<template>
    <div class="searchMenu">
        <div style="position:relative;">
            <v-menu :value="menuOpen" offset-y :open-on-click="false" :close-on-click="false" v-click-outside="clickOutside" ref="searchMenu">
                <template v-slot:activator="{}">
                    <v-text-field
                        full-width
                        class="mx-2 ml-3"
                        dense
                        :label="$t('inputSearchLabel')"
                        single-line
                        v-model="search"
                        :loading="loading"
                        solo
                        flat
                        hide-details
                        background-color="rgba(0,0,0,.05)"
                        append-icon="$magnify_l"
                    ></v-text-field>
                </template>
                <v-card>
                    <v-list dense class="mt-1 py-0" v-for="(itemCat, Categories) in items" :key="Categories">
                        <h4 class="px-3 py-1 h4Spec">{{Categories}}</h4>
                        <v-list-item dense v-for="(item, i) in itemCat" :key="i" @click="goTo(Categories, item)">
                            <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense v-if="totalItems > items.length">
                            <span class="warning--text" style="width:100%; text-align:center;">{{items.length}} résultats sur {{totalItems}} affichés. Affinez votre recherche</span>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </div>
    </div>
</template>

<script>
    import GenericDataService from '@/services/GenericDataService'
    export default {
        name:"SearchMenu",
        data() {
            return {
                items:[],
                menuOpen: false,
                loading:false,
                search:"",
                select:null,
                totalItems: 0
            }
        },
        computed: {
            // totalItems() {
            //     return this.items.flat()
            // }
        },
        methods:{
            clickOutside(){
                if(this.$refs.searchMenu)
                    this.$refs.searchMenu.save();
                // this.search = "";
            },
            goTo(category, item){
                switch (category) {
                    case 'contact':
                    case 'demand':
                        this.$router.push({name:'ContactDetail', params: {contact_id: item.value}})
                        break;
                    case 'user':
                        this.$router.push({name:'UserDetail', params: {user_id: item.value}})
                        break;
                    case 'campaignOut':
                        this.$router.push({name:'OperationCampaignDetail', params: {id: item.value, operation_id: item.operation_id}})
                        break;
                    case 'campaignCall':
                        this.$router.push({name:'OperationCampaignDetail', params: {id: item.value, operation_id: item.operation_id}})
                        break;
                    case 'segment':
                        this.$router.push({name:'SegmentationEditForm', params: {id: item.value}})
                        break;
                    case 'operation':
                        this.$router.push({name:'OperationDetail', params: {operation_id: item.value}})
                        break;
                    default:
                        break;
                }
            },
            searchForItems(){
                this.loading = true
                this.menuOpen = false,
                GenericDataService.postData('search/search', {search: this.search}).then(response => {
                    this.items = response.data.data.data
                    this.totalItems = 0
                    for(const property in this.items){
                        this.totalItems += this.items[property].length
                    }
                    // this.items.forEach((element, index) => {
                    //     this.totalItems += element.length
                    // })
                    //this.totalItems = this._.flatten(response.data.data.data, true)
                    this.menuOpen = true
                    this.$forceUpdate()
                    this.loading = false
                })
            },
        },
        watch: {
            search(val){
                if( val && val.length > 2 && this.select == null){               
                    setTimeout(() => {
                        this.searchForItems()
                    }, 200);
                } 
                if(val == ""){
                    this.select = null
                    this.menuOpen = false
                    this.items = []
                }
            }
        },
    }
</script>

<style lang="scss">
    .searchMenu {
        width: 400px;
        .v-input__icon--append {
            .v-icon {
                font-size: 18px;
            }
        }
    }
    .h4Spec {
        //border-top: 1px solid var(--v-darkgrey-lighten3);
        font-size:14px;
    }
</style>

<template>
    <v-card rounded="lg" height="100%" :disabled="(!(superlead.contact_id && superlead.contact_id.id) && operationData.available) || formDisabled">
        <v-card-title :style="(superlead && superlead.rdv && superlead.rdv.hasOwnProperty('date_start')) ? 'border-bottom: 1px solid #ddd;' : ''">
            <h5 class="pb-0" v-if="superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'RDV'"><b>{{ $t('rdv') }}</b></h5>
            <h5 class="pb-0" v-else-if="superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'PASSAGE'"><b>{{ $t('passage') }}</b></h5>
            <h5 class="pb-0" v-else-if=" superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'INSCRIPTION'"><b>{{ $t('inscription') }}</b></h5>
            <v-chip class="ml-3" small color="warning" v-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('status') && superlead.rdv.status != 'FINISHED' && superlead.rdv.type != 'PASSAGE'">
                <span v-if="superlead.rdv.status == 'DRAFT'">{{$t('tobeconfirmed')}}</span>
                <span v-else-if="superlead.rdv.status == 'CONFIRMED' && (new Date(superlead.rdv.date_start) > new Date())">{{$t('yettocome')}}</span>
                <span v-else-if="superlead.rdv.status == 'CONFIRMED' && (new Date(superlead.rdv.date_start) <= new Date())">{{$t('tobequalified')}}</span>
                <span v-else>
                    {{ $t(superlead.rdv.status) }}
                </span>
            </v-chip>
            <v-chip class="ml-3" small :color="superlead.rdv.is_success == '1' ? 'success' : 'error'" v-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('status') && superlead.rdv.status == 'FINISHED'">{{ $t(superlead.rdv.is_success == '1' ? 'cameRdvs' : 'notComeRdvs') }}</v-chip>            
            <v-spacer></v-spacer>
            <v-btn text depressed class="square" v-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('status') && superlead.rdv.status != 'FINISHED'" @click="confirmDeleteDialog = true">
                <v-icon color="error" small>$trash_d</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="" style="height:calc(100% - 64px)" :key="'rdv_'+keyRdv">
            <div v-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'RDV'">
                <div class="d-flex align-center justify-space-between">
                    <div>
                        <div class="d-flex align-center mt-4">
                            <v-icon color="black" class="mr-4 iconHour">$clock_l</v-icon>
                            <div>
                                <p class="mb-0 black--text"><b>{{ rdvDate }}</b></p>
                                <p class="mb-0 black--text">{{ rdvHourStart.slice(0, 5) }} - {{ rdvHourEnd.slice(0, 5) }}</p>
                            </div>
                        </div>
                        <div class="d-flex align-center mt-4">
                            <v-icon color="black" class="mr-4">$map_l</v-icon>
                            <div v-if="superlead.rdv && superlead.rdv.place && superlead.rdv.place.hasOwnProperty('config')">
                                <p class="mb-0 black--text"><b>{{ superlead.rdv.place.config.name }}</b></p>
                                <p class="mb-0 black--text">{{ superlead.rdv.place.config.adress }}</p>
                                <p class="mb-0 black--text">{{ superlead.rdv.place.config.zip }} {{ superlead.rdv.place.config.city }}</p>
                            </div>
                        </div>
                    </div>
                    <v-btn color="purple" :disabled="superlead.rdv.status == 'FINISHED'" dark depressed small @click="editRdvDate">{{ $t('moveRdv') }}</v-btn>
                </div>
                <div class="mt-4 py-4 d-flex align-center justify-space-between" style="border-bottom: 1px solid #ddd; border-top: 1px solid #ddd;">
                    <div class="d-flex align-center">
                        <v-avatar  v-if="rdvUser && rdvUser.hasOwnProperty('id')" size="40" class="mr-2">
                            <v-img 
                                :src="api+'/user/'+rdvUser.id+'/getAvatar?token='+$store.state.auth.idToken"></v-img>
                        </v-avatar>
                        <v-avatar color="error lighten-5" v-else size="40" class="mr-2">
                            <v-icon color="error">$usertie_d</v-icon>
                        </v-avatar>
                        <p class="mb-0" v-if="rdvUser && (rdvUser.hasOwnProperty('firstname') || rdvUser.hasOwnProperty('name'))"> <b class="black--text">{{ rdvUser.firstname + " " + rdvUser.name }}</b></p>
                        <p class="mb-0 error--text" v-else> <b>{{ rdvUser }}</b></p>
                    </div>
                    <v-btn color="purple" :disabled="superlead.rdv.status == 'FINISHED'" dark depressed small @click="selectOrEditVendor">{{ $t('modify') }}</v-btn>
                </div>
                <div class="mt-4">
                    <label for="" class="black--text"><b>{{ $t('rdvType') }}</b></label>
                    <!-- <div class="my-3" v-if="rdvUser && rdvUser.hasOwnProperty('confVisio') && !rdvUser.confVisio">
                        <p>Présentiel</p>
                    </div> -->
                    <div class="my-3">
                        <v-select class="input-form" :disabled="(rdvUser && rdvUser.hasOwnProperty('confVisio') && !rdvUser.confVisio) || superlead.rdv.status == 'FINISHED'" hide-details dense solo flat @change="resetDraft" v-model="superlead.rdv.visio" :items="visioSelect"></v-select>
                    </div>
                </div>
                <div class="mt-4" v-if="duration_selected">
                    <label for="" class="black--text"><b>{{ $t('duration') }}</b></label>
                    <div class="my-3">
                        <v-select class="input-form" :disabled="!$func.hasRight('lead/update_duration') || superlead.rdv.status == 'FINISHED'" @change="changeDuration" hide-details return-object dense solo flat v-model="duration_selected" :items="duration_available"></v-select>
                    </div>
                </div>
                <div v-if="superlead.rdv && superlead.rdv.status == 'DRAFT'" >
                    <v-tooltip bottom :disabled="!!vendorSelected">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="display: inline-block">
                                <v-btn small depressed color="pink" @click="confirmRdv" dark class="mt-3" :disabled="!vendorSelected">{{ $t('confirm') }}</v-btn>
                            </div>
                        </template>
                        <span>{{$t('vendorRequired')}}</span>
                    </v-tooltip>
                </div>
                <div v-if="superlead.rdv && superlead.rdv.status == 'FINISHED'" >
                    <v-btn color="pink" class="flex-grow-0 mt-3" @click="showPlanning" dark small depressed>{{$t('newrdv')}}</v-btn>
                </div>
            </div>
            <div v-else-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'PASSAGE'">
                <div class="black--text placeText label mt-4 ">
                    <b>Lieu </b>
                </div>
                <v-select :readonly="placeSelector.length == 1" :items="placeSelector" v-model="superlead.rdv.place" :item-value="'id'" return-object filled dense hide-details :label="$t('choosePlaceLabel')" solo flat class="input-form mt-1" :no-data-text="$t('no-data-text')">
                    <template v-slot:selection="data">
                        {{ data.item.config.name }}
                    </template>
                    <template v-slot:item="data">
                        {{ data.item.config.name }}
                    </template>
                </v-select>
                <div class="black--text placeText label mt-4">
                    <b>{{$t('vendor')}} </b>
                </div>
                <v-select :items="vendorPassage" v-model="vendorSelected" hide-details filled dense :label="$t('chooseVendorLabel')" solo flat class="input-form mt-1 mb-4" :no-data-text="$t('no-data-text')"></v-select>
                <VDatetimePicker :hideDetails="true" :field="{title:'date_start', props: {required:true}}" :fieldName="'date_start'" :model="superlead.rdv"  />
                <span v-if="errorStartPassage != ''" class="error--text">{{$t(errorStartPassage) }}</span>
                <VDatetimePicker class="mt-2" :hideDetails="true"  :field="{title:'date_end', props: {required:true}}" :fieldName="'date_end'" :model="superlead.rdv"  />
                <span v-if="errorEndPassage != ''" class="error--text">{{$t(errorEndPassage) }}</span>
                <v-textarea v-model="superlead.rdv.comment" :label="$t('comment')" class="input-form mt-2" solo flat filled hide-details></v-textarea>
            </div>
            <div v-else-if="superlead && superlead.rdv && superlead.rdv.hasOwnProperty('type') && superlead.rdv.type == 'INSCRIPTION'">
                <div class="black--text placeText label mt-4 ">
                    <b>Lieu </b>
                </div>
                <v-select :readonly="placeSelector.length == 1" :items="placeSelector" hide-details v-model="superlead.rdv.place" :item-value="'id'" return-object filled dense :label="$t('choosePlaceLabel')" solo flat class="input-form mt-1" :no-data-text="$t('no-data-text')">
                    <template v-slot:selection="data">
                        {{ data.item.config.name }}
                    </template>
                    <template v-slot:item="data">
                        {{ data.item.config.name }}
                    </template>
                </v-select>
                <VCustomNumberByIcon :field="{title:'accompagnants', props: {required:true}}" class="mt-6" :fieldName="'accompagnants'" :model="superlead.rdv" />
                <v-textarea v-model="superlead.rdv.comment" :label="$t('comment')" class="input-form mt-2" solo flat filled hide-details></v-textarea>
                <div v-if="superlead.rdv && superlead.rdv.status == 'DRAFT' && rdvUser && rdvUser.hasOwnProperty('id')" >
                    <v-btn small depressed color="pink" @click="confirmRdv" dark class="mt-3">{{ $t('confirm') }}</v-btn>
                </div>
            </div>
            <div class="noRdv d-flex align-center justify-center" v-else-if="operationData.available">
                <div class="d-flex flex-column align-center">
                    <v-img contain width="100" :src="'/img/rdv_icon.png'"></v-img>
                    <v-btn color="pink" v-if="operationData.type != 'EVENT'" class="flex-grow-0 mt-3" @click="showPlanning" dark small depressed>{{$t('takerdv')}}</v-btn>
                    <v-btn color="pink" v-if="operationData.passage" @click="createPassageBase" dark small depressed class="mt-3">{{$t('addPassageLabel')}}</v-btn>
                    <v-btn color="pink" v-if="operationData.type == 'EVENT'" class="flex-grow-0 mt-3" @click="createInscriptionBase" dark small depressed>{{$t('addInscription')}}</v-btn>
                </div>
            </div>
            <div class="noRdv d-flex align-center justify-center" v-else>
                <div class="textNoPlanning">
                    <div>{{ $t('NoTeamPlanningSetted') }}</div>
                    <v-btn depressed small dark color="pink" class="mt-3"  v-if="$func.hasRight('operation/update')" @click="openTeamAttribution = true" >{{ $t('configure') }}</v-btn>
                    <teamAttributionPopup :operation="operations[0]" :operationId="operationId" @close="closeTeamAttribution" :openTeamAttribution="openTeamAttribution" />
                </div>
            </div>
        </v-card-text>
        
        <v-dialog class="rounded-lg" persistent width="700" v-model="confirmDeleteDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('confirmDeleteRdvTitle')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="confirmDeleteDialog = false">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="clearRdv" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{$t('confirmDeleteRdvText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
        <PlanningDialogComponent
        ref="componentPlanning"
        :type="planningType"
        :contact_id="$route.params.contact_id "
        :requestForRdv="requestForRdv"
        :operationId="operationId"
        :operations="operations"
        :teamPlannings='teamPlannings'
        :vendeurId="vendorId"
        :appointements="appointements"
        :contact="contact"
        :superlead="superlead"
        :key="'planningDialog_'+planningKey"
        v-if="operationId != 0"
        />
        <SelectVendorRdvDialogComponent :multi="possibleVendors" :rdvInfo="superlead.rdv" :contact="contact" :displaySelectVendor="displaySelectVendor" @closeVendorDialog="closeVendorDialog" @confirmVendorSelected="confirmVendorSelected"/>
    </v-card>
</template>

<script>
import PlanningDialogComponent from '@/components/dialogs/PlanningDialogComponent'
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import SelectVendorRdvDialogComponent from '@/components/dialogs/SelectVendorRdvDialogComponent'
import VDatetimePicker from "@/components/forms/inputs/v-datetime-picker";
import VCustomNumberByIcon from "@/components/forms/inputs/v-custom-numberByIcon";
import teamAttributionPopup from '../../../modules/operations/operationsComponents/teamAttributionPopup.vue';

export default {
    name: "SRdv",
    props: ["superlead", "operationData", "formDisabled"],
    mixins: [dateManipulation],
    components: {
        PlanningDialogComponent,
        SelectVendorRdvDialogComponent,
        VDatetimePicker,
        VCustomNumberByIcon,
        teamAttributionPopup
    },
    data() {
        return {
            confirmDeleteDialog: false,
            api: process.env.VUE_APP_API_URL,
            planningType: "",
            requestForRdv: [],
            operationId:0,
            keyRdv:0,
            operations:[],
            teamPlannings: [],
            vendorId:'all',
            appointements: [],
            planningKey: 0,
            contact: {},
            possibleVendors : [],
            displaySelectVendor: false,
            visioSelect: [
                {
                    text: "Visio",
                    value: "1"
                },
                {
                    text: "Présentiel",
                    value: "0"
                }
            ],
            duration_available: [],
            duration_selected: {},
            errorStartPassage:"",
            errorEndPassage:"",
            vendorPassage : [],
            operation: {},
            selectedPlace : {},
            openTeamAttribution: false,
            operations: []
        };
    },
    created() {
        this.initTeamPlannings()
        GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) =>{
            this.operation = response.data.data
            this.operations.push(this.operation)
        })
        if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_start && this.superlead.rdv.date_end && this.operationId)
            this.initDurationAvailable()
    },
    watch: {
        rdvUser: function(val){
            if(val && val.name && this.superlead.rdv && this.superlead.rdv.status && this.superlead.rdv.status == 'CONFIRMED')
                this.$emit('rdvUserChange', {firstname: val.firstname, name: val.name, id: val.id});
        }
    },
    computed: {
        rdvUser(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.links && this.superlead.rdv.links.user && this.superlead.rdv.links.user[0] && this.teamPlannings) {
                return this.teamPlannings.find((e) =>e.id == this.superlead.rdv.links.user[0])
            } else {
                return this.$t("vendorNotSelected");
            }
        },
        rdvDate(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_start) {
                return this.timestampToDate(new Date(this.superlead.rdv.date_start.split(' ')[0]).getTime() / 1000, 'dayMonthYear')
            }
            return ""
        },
        rdvHourStart(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_start) {
                return this.superlead.rdv.date_start.split(' ')[1]
            }
            return ""
        },
        rdvHourEnd(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_end) {
                return this.superlead.rdv.date_end.split(' ')[1]
            }
            return ""
        },
        placeSelector(){
            if(this.operation && this.operation.places)
                return this.operation.places.filter((e) => e.config.entity_id.includes(this.superlead.entity_id.id))
        },
        vendorSelected: {
            // getter
            get() {
                if(this.superlead && this.superlead.rdv && this.superlead.rdv.links && this.superlead.rdv.links.user && this.superlead.rdv.links.user[0])
                    return this.superlead.rdv.links.user[0]
                return null
            },
            // setter
            set(newValue) {
                this.superlead.rdv.links.user = [newValue]
            }
        }
    },
    methods: {
        initBasePassageInscription(){
            this.superlead.rdv.date_start = this.operation['config.date_start']
            this.superlead.rdv.date_end = this.operation['config.date_end']
            this.superlead.rdv.accompagnants = 0
            this.superlead.rdv.links = {}
            this.superlead.rdv.links.user = []
            this.superlead.rdv.operation_id = this.operationId
            if(this.$store.state.auth.currentUser.loggedAs){
                this.superlead.rdv.user_id = this.$store.state.auth.currentUser.loggedAs.id
            } else {
                this.superlead.rdv.user_id = this.$store.state.auth.currentUser.profile.id
            }
            this.superlead.rdv.place = this.placeSelector[0]
            this.keyRdv ++
        },
        createInscriptionBase(){
            let rdv = {}
            rdv.type = 'INSCRIPTION'
            rdv.status ='DRAFT'
            this.$set(this.superlead, 'rdv', rdv)
            this.initBasePassageInscription()
        },
        createPassageBase(){
            let rdv = {}
            rdv.type = 'PASSAGE'
            rdv.status ='CONFIRMED'
            this.$set(this.superlead, 'rdv', rdv)

            this.initBasePassageInscription()
        },
        resetDraft(){
            this.superlead.rdv.status = 'DRAFT'
        },
        validateSRdv(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.hasOwnProperty('status')) {
                if(this.superlead.rdv.date_start && this.superlead.rdv.date_end && this.superlead.rdv.status)
                    return true;
                return false;
            }
            return true;
        },
        changeDuration(){ 
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_start && this.superlead.rdv.date_end && this.duration_selected.value){
                this.superlead.rdv.date_end =  this.superlead.rdv.date_end.split(" ")[0]+" "+this.parseFloatToHour(this.parseHourToFloat(this.rdvHourStart) + (this.duration_selected.value / 60))
                this.superlead.rdv.status = 'DRAFT'
            }
                
        },
        
        clearRdv(){
            if(this.superlead.rdv.id){
                let url = "rdv/"
                if(this.superlead.rdv.type == 'PASSAGE' || (this.superlead.rdv.type == 'RDV' && this.superlead.rdv.status == 'DRAFT')){
                    //suppression
                    url += this.superlead.rdv.id+"/deleteRdv"
                } else {
                    //cancelled
                    url += this.superlead.rdv.id+"/cancelRdv"
                }
                GenericDataService.postData(url).then((response) => {
                    this.superlead.rdv = {}
                    this.confirmDeleteDialog = false
                })
            } else {
                this.superlead.rdv = {}
                this.confirmDeleteDialog = false
            }
        },
        initDurationAvailable(){
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.date_start && this.superlead.rdv.date_end && this.operationId) {
                let params = '?date_start='+this.superlead.rdv.date_start+'&date_end='+this.superlead.rdv.date_end+'&operation_id='+this.operationId
            
                if(this.superlead && this.superlead.rdv && this.superlead.rdv.links && this.superlead.rdv.links.user && this.superlead.rdv.links.user[0])
                    params += '&user_id='+ this.superlead.rdv.links.user[0]
                
                GenericDataService.getData('/rdv/getRdvDurationAvailableSuperlead'+params).then((response) => {
                    this.duration_available = response.data.data
                    this.duration_selected = response.data.data.find((e) => e.selected)
                })
            }
            
        },
        closeVendorDialog(){
            this.displaySelectVendor = false
        },
        confirmVendorSelected($evt){
            if($evt){
                this.$emit('confirmVendorSelected', $evt);
                this.$set(this.superlead.rdv.links, 'user', [parseInt($evt)])
                //this.superlead.rdv.links.user = [parseInt($evt)]
                this.displaySelectVendor = false

                let vendor = this.possibleVendors.find(({ id }) => id === $evt);
                if(!vendor.confVisio)
                    this.superlead.rdv.visio = "0";
            }
        },
        selectOrEditVendor() {
            GenericDataService.getData('/operation/'+this.operationId+'/getTeamPlanningsDispo?place_id='+this.superlead.rdv.place.id+'&from='+this.superlead.rdv.date_start+'&to='+this.superlead.rdv.date_end+(this.superlead.rdv.id ? '&excludeRdv='+this.superlead.rdv.id : '')).then((response) => {
                this.possibleVendors = response.data.data
                this.displaySelectVendor = true
            })
        },
        initTeamPlannings(){
            this.operationId = this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id;
            GenericDataService.getData('/operation/'+(this.operationId)+"/getTeamPlannings?withStats=1").then( async (response) => {
                if(!response || !response.data || !response.data.data || !response.data.data.data){
                    this.$store.dispatch('ADD_ERROR', {errorMessage: "NoTeamPlanningSetted", type: 'user', link:{name:"OperationsDetailTeam", params:{operation_id: this.operationId}}})
                } else {
                    this.teamPlannings = response.data.data.data
                }
                GenericDataService.getData('/operation/'+this.operationId+'/getTeamPlanningSelectList').then((response) => {
                    this.vendorPassage = response.data.data
                })
            })
              
        },
        confirmRdv(){
            //if(!this.$store.state.auth.is_presta)
                this.$emit('rdvUserChange', {firstname: this.rdvUser.firstname, name: this.rdvUser.name, id: this.rdvUser.id});
            
            this.superlead.rdv.status = 'CONFIRMED'
        },
        showPlanning() {
            this.operations = []
            //Définition des props pour le planning
            this.planningType = "ADD_RDV_REQUEST"
            
            //Demandes cliquées
            
            this.requestForRdv = this.superlead.demands.filter((e) => e.id).map((e) => e.id);
            GenericDataService.getData('/operation/'+this.operationId+'/get').then( async (response) => {
                this.operations.push(response.data.data)      

                if(this.contact.assignment_user_id && this.contact.assignment_user_id.id && this.teamPlannings.filter((e) => e.id == this.contact.assignment_user_id.id).length > 0){
                    let contactVendorId = this.contact.assignment_user_id.id
                    this.vendorId = contactVendorId
                } else {
                    if(this.teamPlannings.filter((e) => e.id == this.$store.state.auth.currentUser.id).length > 0){
                        this.vendorId = this.$store.state.auth.currentUser.id
                    } else {
                        this.vendorId = "all"
                    }
                }

                if(this.teamPlannings && this.teamPlannings.length > 0){
                    let vm = this;
                    //On lance la popup planning
                    await this.$refs.componentPlanning.open().then(function (response) {
                        if(response.method == "addRdv"){
                            //préenregistrement du rdv : construction des datas (différent entre BDD et front planning)
                            let rdvToRecord = {}
                            rdvToRecord.type = "RDV"
                            rdvToRecord.date_start = response.rdv.date+" "+response.rdv.start
                            rdvToRecord.date_end = response.rdv.date+" "+response.rdv.end
                            rdvToRecord.operation_id = vm.operationId
                            if(vm.$store.state.auth.currentUser.loggedAs){
                                rdvToRecord.user_id = vm.$store.state.auth.currentUser.loggedAs.id
                            } else {
                                rdvToRecord.user_id = vm.$store.state.auth.currentUser.profile.id
                            }
                            rdvToRecord.place = response.rdv.place
                            rdvToRecord.status = 'DRAFT'
                            rdvToRecord.visio = "0"
                            rdvToRecord.links = {}
                            if(vm.requestForRdv.length > 0)
                                rdvToRecord.links.demand = vm.requestForRdv.map((e) =>  parseInt(e.id ? e.id : e))
                            
                            rdvToRecord.links.contact = vm.contact.id ? [parseInt(vm.contact.id)] : []
                            if(response.rdv.ref_id){
                                rdvToRecord.links.user = [parseInt(response.rdv.ref_id)]
                            }
                            vm.superlead.rdv = rdvToRecord
                            vm.appointements.push(response.rdv);

                            vm.initDurationAvailable();
                        }
                    });
                }
            })
        },
        async editRdvDate(){
            this.planningType = "EDIT_RDV_DATE_REQUEST" //changement de type de planning 
            if(this.superlead && this.superlead.rdv && this.superlead.rdv.links && this.superlead.rdv.links.user && this.superlead.rdv.links.user[0])
                this.vendorId = this.superlead.rdv.links.user[0]
            let vm = this;
            GenericDataService.getData('/operation/'+this.operationId+'/get').then( async (response) => {
                vm.operations = [];
                vm.operations.push(response.data.data)

                await this.$refs.componentPlanning.open().then(function (response) {
                    if(response && response.rdv){
                        console.log(response.rdv)
                        vm.superlead.rdv.date_start = response.rdv.date+" "+response.rdv.start
                        vm.superlead.rdv.date_end = response.rdv.date+" "+response.rdv.end
                        vm.superlead.rdv.status = 'DRAFT'
                        if(response.rdv.ref_id){
                            vm.superlead.rdv.links.user = [parseInt(response.rdv.ref_id)]
                        } else {
                            vm.superlead.rdv.links.user = []
                        }

                        vm.initDurationAvailable();
                    }
                });
            })
        },
        closeTeamAttribution(){
            this.$emit('checkOperationData')
            this.openTeamAttribution = false;
        }
    },
}
</script>

<style lang="scss">
    .textNoPlanning {
        width:75%;
        text-align:center;
    }
    .noRdv {
        height:100%;
        border: 1px dashed #DDD;
    }
    .iconHour {
        width:24px;
        height:20px;
        svg {
            width:20px;
            height:20px;
        }
    }
    .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color:#DDD !important;
        color: #BBB !important;
    }
    .placeText {
        font-size:15px;
    }
</style>

<template>
    <v-row class="exchangeHistoryComponent">
        <v-col cols="12" class="heading-filters" v-if="!isFromPopup">
            <div class="d-flex align-center">
                <span class="mr-6">{{ $t('sectionFilters') }}</span>

                <div v-for="(filter, index) in headingFilters" :key="filter.name" :class="filter.type">
                    <span v-if="filter.type == 'select'" :id="'headingFilters' + index"  class="headingFiltersSelect">
                        <v-autocomplete
                            @focus="getItems(filter)"
                            v-model="filter.value"
                            :items="getProperties(filter)"
                            item-text="text"
                            item-value="value"
                            :label="$t(filter.title)"
                            :ref="filter.name"
                            @change="filterResults(filter)"
                            :attach="'#headingFilters' + index"
                            multiple
                            clearable
                            hide-details
                            filled
                            dense
                            class="selectField mr-6 pt-0 mt-0"
                            :no-data-text="$t('no-data-text')"
                        ></v-autocomplete>
                    </span>
                    <span v-else-if="filter.type == 'daterange'" id="dateRangeFilter" class="dateRangeFilter">
                        <vDateRangePicker @change="filterResults" :attach="'#dateRangeFilter'" class="mr-6 " style="width:250px;" v-model="filter.value" :props="{ filled: true, height: '46' }" />
                    </span>
                </div>
            </div>

            <div class="d-flex align-center">
                <div v-for="filter in searchInput" :key="filter.name" :class="filter.type">
                    <v-text-field
                        v-model="filter.value"
                        @focus.stop=""
                        @keyup.enter="filterResults"
                        :label="$t(filter.title)"
                        :placeholder="searchFilter"
                        filled
                        dense
                        height="46"
                        hide-details
                        class="mr-0 searchInput"
                        :ref="filter.name"
                    >
                        <template slot="append">
                            <v-btn class="square" text @click="filterResults" small>
                                <v-icon>$magnify_l</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </div>

                <div>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn min-width="39" height="39" v-bind="attrs" v-on="on" v-show="isFiltered" @click.stop="clearFilters" small depressed color="primary" class="ml-4 btn-icon">
                                <v-icon small color="primarytext" dark>
                                    $clear
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("clearFiltersDatatable")}}</span>
                    </v-tooltip>
                </div>
            </div>
        </v-col>

        <v-col v-if="!exchanges.length" cols="12" class="px-0">
            <v-alert class="mb-0 mx-4" border="left" color="info" text>
                {{ $t("emptyExchangesInfoMessage") }}
            </v-alert>
        </v-col>

        <v-col v-else cols="12" id="col_scrollable" class="my-4" v-scroll.self="scrollTest" :style="'max-height: ' + (isFromPopup ? '180' : '500') + 'px; overflow-y: auto;'">
            <v-row id="innerMedia">
                <v-col v-if="!endScroll" cols="12" id="loaderCol" class="d-flex justify-center mb-4" style="height: 30px;">
                    <v-progress-circular v-if="loading" :size="30" :width="3" color="primary" indeterminate ></v-progress-circular>
                </v-col>

                <v-col cols="12" v-for="(exchange, index) in exchanges" :key="index" class="pb-0">
                    <v-card width="48%" :class="exchange.type === 'out' ? 'float-right' : ''" class="no-shadow d-table" :color="exchange.type === 'out' ? '#F6D6EA' : '#F5F5F5'">
                        <v-card-text class="d-flex">
                            <v-chip :color="exchange.canal.color" dark small>
                                <v-icon x-small class="mr-2">${{ exchange.canal.icon }}</v-icon>
                                {{ $t(exchange.canal.name).toUpperCase() }}
                            </v-chip>
                            <div class="mt-1 ml-4" v-if="exchange.canal.name === 'EMAIL'">
                                <span v-if="exchange.title"><b>{{ $t('subject') }} :</b> {{ exchange.title }}</span>
                                <span v-if="exchange.retroplanning_id && exchange.retroplanning_id.name"><br><b>{{ $t('campain') }} :</b> {{ exchange.retroplanning_id.name }}</span>
                                <span v-if="exchange.template_id && exchange.template_id.params"><br><b>{{ $t('from') }} :</b> {{ exchange.template_id.params.senderName }} <{{ exchange.template_id.params.sender }}></span>
                            </div>
                            <v-icon v-if="exchange.canal.name === 'EMAIL' && exchange.attachments.length" small class="ml-4 mt-1">$paperclip_d</v-icon>
                            <v-spacer></v-spacer>
                            <div class="mt-1">
                                <v-icon small class="mr-2">$clock_l</v-icon>
                                <span>{{ parseFullDateToFr(exchange.date) }}</span>
                            </div>
                        </v-card-text>

                        <v-card-text class="pt-0" v-if="exchange.canal.name === 'EMAIL'">
                            <div v-if="exchange.retroplanning_id && exchange.retroplanning_id.id" class="d-flex justify-center">
                                <div v-html="exchange.message" @click="emailOpened = emailOpened === exchange.id ? null : exchange.id" :style="'width: 80%; cursor: pointer; ' + (emailOpened === exchange.id ? '' : 'max-height: 250px; overflow-y: auto;')"></div>
                            </div>
                            <span v-else v-html="exchange.message"></span>

                            <div v-if="exchange.attachments.length" class="d-flex align-center mt-4">
                                <v-icon small>$paperclip_d</v-icon>

                                <a v-for="attachment in exchange.attachments" :href="attachment.url" class="ml-2" target="_blank">{{ attachment.title }}</a>
                            </div>
                        </v-card-text>

                        <v-card-text class="pt-0" v-if="exchange.canal.name === 'SMS' || exchange.canal.name === 'MMS'">
                            <v-img v-if="exchange.attachments.url" class="mb-4" max-width="337px" :src="exchange.attachments.url"></v-img>
                            <span v-html="exchange.message"></span>
                        </v-card-text>

                        <v-card-text class="pt-0" v-if="exchange.canal.name === 'VMS'">
                            <audio id="audioPreview" controls controlsList="nodownload" :src="exchange.attachments[0].url" style="width: 100%;"></audio>
                        </v-card-text>

                        <v-card-text class="pt-0" v-if="exchange.canal.name === 'CALL'">
                            <span v-if="exchange.type === 'out'"><b>{{ getUserFullName(exchange.user_id) }}</b> {{ $t('has_recorded_call_to') }} <b>{{ getUserFullName(exchange.contact_id) }}</b></span>
                            <span v-else><b>{{ getUserFullName(exchange.contact_id) }}</b> {{ $t('has_recorded_call_to') }} <b>{{ getUserFullName(exchange.user_id) }}</b></span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';
import vDateRangePicker from '@/components/forms/inputs/v-daterange-picker';

export default {
    name: "exchangeHistoryComponent",
    props: ["isFromPopup", "historyKey"],
    components: { vDateRangePicker },
    mixins:[dateManipulation],
    data() {
        return {
            url: '/exchange/list',
            filters: [],
            loading: false,
            page: 0,
            endScroll: false,
            exchanges: [],
            isFiltered: false,
            emailOpened: null,
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        contact(val) {
            if(val) {
                this.loadData();
            }
        },
        historyKey(val) {
            console.log(val);
            this.loadData();
        }
    },
    computed: {
        headingFilters: function() {
            if(this.filters && this.filters.length > 0){
                return this.filters.filter((e) => e.header && e.type != 'input' && e.type != "bar_filter" && e.name != "contact_id")
            }
        },
        searchInput: function() {
            if(this.filters && this.filters.length > 0){
                return this.filters.filter((e) => e.header && e.type == 'input')
            }
        },
        searchFilter: function(){
            if(this.filters && this.filters.length > 0){
                var filter = this.filters.find((e) => e.name == 'search')
                var fieldsArray = [];
                if(filter.field){
                    for (let index = 0; index < filter.field.length; index++) {
                        const element = filter.field[index];
                        fieldsArray.push(this.$t(element))
                    }
                    return this.$t('searchIn') + " : " + fieldsArray.join(', ')
                }
            }
        },
        contact: function () {
            return this.$store.state.contact.contact;
        },
        exchangeForDrawer: function () {
            return this.$store.state.exchange.exchangeForDrawer;
        },
        superleadForDrawer: function () {
            return this.$store.state.exchange.superleadForDrawer;
        },
    },
    methods: {
        scrollTest($evt) {
            if(!this.endScroll && this.isInViewport('loaderCol') && this.loading == false){
                this.loading = true;
                this.page++;
                this.loadData(true);
            }
        },
        isInViewport(id) {
            const rect = document.getElementById(id).getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        loadData(infiniteScroll = false) {
            if(!infiniteScroll) {
                this.endScroll = false;
                this.page = 0;
            }

            let payload = {
                filters: {}
            }

            if(this.filters && this.filters.length > 0){
                this.filters.forEach(filter => {
                    payload.filters[filter.name] = filter.value;
                });
            }

            payload.filters['contact_id'] = this.contact.id;

            if(this.exchangeForDrawer.id)
                payload.filters['exchange_id'] = this.exchangeForDrawer.id;

            if(this.superleadForDrawer.id)
                payload.filters['superlead_id'] = this.superleadForDrawer.id;

            GenericDataService.postData(this.url + "?pageLen=5&pageNum=" + this.page, payload).then((response) => {
                if(infiniteScroll) {
                    this.addResultExchanges(response);
                } else {
                    this.addExchanges(response);
                }

                this.isFiltered = this.checkPrefiltered()

                this.loading = false
            });
        },
        addExchanges(response) {
            this.exchanges = response.data.data.data.reverse();
            this.filters = response.data.data.filters;
            if(this.exchanges.length == response.data.data.totalItems) {
                this.endScroll = true
            }

            if(this.exchanges.length) {
                setTimeout(() => {
                    const container = this.$el.querySelector('#col_scrollable');
                    container.scrollTop = container.scrollHeight;
                }, 200);
            }
        },
        addResultExchanges(response) {
            const container = this.$el.querySelector('#col_scrollable');
            const prevContainerHeight = container.scrollHeight;

            this.exchanges.unshift(...response.data.data.data.reverse());
            if(this.exchanges.length == response.data.data.totalItems) {
                this.endScroll = true
            }

            setTimeout(() => {
                const newContainerHeight = container.scrollHeight;
                container.scrollTop = container.scrollTop + (newContainerHeight - prevContainerHeight);
            }, 200);
        },
        //Filtrer les résultats
        filterResults(filter){
            // Permet de blur un field pour éviter le bug du no data available quand il n'y a plus de value
            if(filter && filter.value && Array.isArray(filter.value) && filter.value.length == 0 && this.$refs[filter.name] && this.$refs[filter.name][0]) {
                this.$refs[filter.name][0].blur();
            }
            this.page = 0;
            this.loadData();
        },
        getProperties(filter) {
            if(filter.to_trad && !filter.alreadyTrad) {
                for(var i = 0; i < filter.properties.length; i++) {
                    
                    if(typeof filter.properties[i] === 'object') {
                        filter.properties[i].text = this.$t(filter.properties[i].text);
                    } else {
                        filter.properties[i] = {
                            'value': filter.properties[i],
                            'text': this.$t(filter.properties[i]),
                        }
                    }
                }
                
            } else {
                let newProperties = [];
                if(filter.properties && filter.properties.length > 0){
                    filter.properties.forEach(element => {
                        if(typeof element === 'object') {
                            if(element.to_trad){
                                console.log('trad', element.text)
                                newProperties.push({
                                    'value': element.text,
                                    'text': this.$t(element.text),
                                });
                            } else {
                                newProperties.push({
                                    'value': element,
                                    'text': element,
                                });
                            }
                        } else {
                            newProperties.push({
                                'value': element,
                                'text': element,
                            });
                        }
                    });
                }
            
            }
            filter.alreadyTrad = true;
            return filter.properties;
        },
        // Méthode de get des objets pour les select en ajax
        getItems(filter){
            if(filter.method =="ajax"){
                GenericDataService.getData(filter.query).then((response) => {
                    filter.properties = response.data.data
                })
            } else if(filter.selectDataUrl){

                let url = filter.selectDataUrl;
                if(url.includes('{{entity_id}}')){
                    url = url.replace('{{entity_id}}', this.$store.state.auth.currentUser.profile.entity_id)
                }

                GenericDataService.getData(url).then((response) => {
                    filter.properties = response.data.data;
                    filter.alreadyTrad = false;
                    this.$forceUpdate()
                })

            }
        },
        checkPrefiltered() {
            if(!this.filters || !this.filters.filter)
                return false;
            let filters = this.filters.filter((e) => e.value && e.value != "" && e.value.length > 0 && e.type != 'switch' && e.name !== "contact_id");
            if(filters.length > 0){
                return true
            } else {
                return false
            }
        },
        //Méthodes pour clear les filtres
        clearFilters() {
            this.filters.forEach(filter => {
                if(filter.type == "input") {
                    filter.value = ""
                } else if(filter.type == 'radio') {
                    filter.value = true;
                }  else if(filter.type == 'switch') {
                    filter.value = false;
                } else {
                    filter.value = [];
                }
            });
            this.isFiltered = false
            this.filterResults();
        },
        getUserFullName(user) {
            let fullname = "";

            if(user && user.firstname) fullname += user.firstname + " ";
            if(user && user.name) fullname += user.name;

            return fullname;
        },
    }
}
</script>

<style lang="scss">
.exchangeHistoryComponent {
    .headingFiltersSelect {
        position:relative;
        .v-menu__content {
            max-width: unset !important;
        }
    }
    .dateRangeFilter {
        position:relative;
    }


    .heading-filters {
        display:flex;
        justify-content: space-between;
        align-items:center;

        .input {
            //margin-left: 10px;
            min-width:300px !important;
            @media (-webkit-device-pixel-ratio: 1.25) {
                min-width:250px !important;
            }
        }
        .v-btn__content {
            font-size:1em;
            @media (-webkit-device-pixel-ratio: 1.25) {
                font-size:0.9em;
            }
        }
        .v-input__control{
            min-height: 30px !important;
        }
        .v-input__slot{
            min-height: 30px !important;
        }
    }
}
</style>
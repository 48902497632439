<template>
    <div>
        <v-breadcrumbs
            :items="breadCrumbs"
            divider="/"
            class="mt-3"
            >
            <template v-slot:item="props">
                <router-link :to="props.item.href" v-if="!props.item.last && !props.item.disabled">
                    <v-breadcrumbs-item
                        :class="[props.item.disabled && 'disabled']"
                        v-if="props.item.text != 'home'"
                    >
                        {{ $t(props.item.text) }}
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-item
                        :class="[props.item.disabled && 'disabled']"
                        v-else
                    >
                        <v-icon small color="primary">
                            $home_d
                        </v-icon>
                    </v-breadcrumbs-item>
                </router-link>
                <v-breadcrumbs-item
                    :class="[props.item.last && 'primary--text']"
                    v-else
                >
                    <span v-if="props.item.text != 'home'">{{ $t(props.item.text) }}</span>
                    <v-icon small color="primary" v-else>$home_s</v-icon>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </div>
</template>
<script>
export default {
    name:"BreadCrumb",
    data() {
        return {

        }
    },
    created() {

    },
    computed: {
        breadCrumbs () {
            let pathArray = this.$route.path.split('/')
            pathArray.shift()
            const breadCrumbs = []
            // needed to handle the intermediary entries for nested vue routes
            let routes = this.$route.matched.filter((e) => e.meta.breadCrumb && e.meta.breadCrumb != "")
            let test= [];
            if(this.$route.meta.title != "home"){
                test.push(
                        {
                            href: {name: "Home"},
                            disabled: false,
                            text: "home"
                        }
                    )
            }
            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];

                if(route.meta.breadCrumb && route.meta.breadCrumb != ""){
                    let newPath = route.path
                    let paramRoute = route.path.match(/:([a-z_]+)/)
                    if(paramRoute){
                        let id = this.$route.params[paramRoute[1]]
                        newPath = newPath.replace(paramRoute[0], id)
                    }
                    if(newPath.slice(-1) != '/'){
                        newPath = newPath+'/'
                    }
                    let isInConfig = false
                    if(route.meta.breadCrumb == "operationDetail"){
                        isInConfig = routes.find((e) => e.path.includes('config'))
                    }
                    test.push(
                        {
                            href: newPath,
                            disabled: (index + 1 === routes.length) || (route.meta.rights && !this.$func.hasRight(route.meta.rights)) || route.meta.disabled || isInConfig,
                            last: index + 1 === routes.length,
                            text: route.meta.breadCrumb
                        }
                    )
                }
            }
            return test
        }
    },
}
</script>
<style lang="scss">
    .v-breadcrumbs {
        padding:0px!important;

        .v-breadcrumbs__item{
            font-size:14px !important;
        }


        a {
            text-decoration:none;
            color: grey !important;

        }
        .disabled {
            font-weight:bold;

            //color: #1976d2;
        }
    }
    .v-breadcrumbs li:nth-child(even){
        padding: 0 5px !important;
    }
</style>

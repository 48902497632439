<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="true"
    :hide-overlay="true"
    :transition="'dialog-bottom-transition'"
    scrollable
    persistent
    @keydown.esc="cancel"
  >
    <v-card tile>
      <div class="globalAgenda">
        <v-toolbar flat dark color="primary" class="toolbar bg-gradient">
          <v-btn depressed color="white" class="square mr-2" text @click.native="cancel"><v-icon dark small>$prev</v-icon></v-btn>
          <v-toolbar-title>Agenda</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn depressed outlined color="white" class="mr-3" @click.native="cancel">{{$t('cancelLabel')}}</v-btn>
            <v-btn v-if="type=='EDIT_RDV_REQUEST'" depressed outlined color="white" class="mr-3" @click.native="cancel">{{$t('confirmLabel')}}</v-btn>
        </v-toolbar>
        <v-card-text class="pa-0" style="height:100%;">
          <div class="agendaContent">
            <Planning 
              :type="type"
              :genericUnavailable="genericUnavailable"
              :vendeurId="vendeurId"
              :entityId="this.$store.state.auth.currentUser.profile.entity_id"
              :isMine="isMine"
              :operationId="operationId"
              :creneau="creneau"
              :operations="operations"
              :appointements="appointements"
              :contact="contact"
              @addRdv="addRdv"
              @getPlanningIndispo="getPlanningIndispo"
              @recordInfos="recordInfos"
              :popup="dialog"
              :superlead="superlead"
              mode="popup"
            />
          </div>
        </v-card-text>
      </div>
      <div style="flex: 1 1 auto"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import Planning from '@/components/planning/Planning';
import GenericDataService from '@/services/GenericDataService';

export default {
  name: "PlanningDialogComponent",
  props:['contact_id', 'requestForRdv', "type", "vendeurId", "contact", "multi", "operationId", "operations", "teamPlannings", "appointements", "superlead"],
  components: {
    Planning,
    GenericDataService,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      dialog: false,
      resolve: null,
      // Temporaires
      genericUnavailable: [],
      //vendeurId: 4,
      isMine: false,
      //operationId:14,
      creneau: '60',
      // multi: [
      //   {
      //     id: 1,
      //     name: "Jacques Dupont",
      //     univers: ["Neuf", "Occasion"],
      //     annotation: "Annotation Vendeur",
      //     visioAvailable: true,
      //     stats: {
      //       nbRdv:10,
      //       nbDispo: 25, //gris
      //       nbConfirmed:8, //bleu
      //       nbUnconfirmed:2, //jaune
      //       nbIndispo:8 //rouge
      //     }
      //   },
      //   {
      //     id: 2,
      //     name: "Clémence Domise",
      //     univers: ["Occasion"],
      //     annotation: "Annotation Vendeuse",
      //     visioAvailable: false,
      //     stats: {
      //       nbRdv:14,
      //       nbDispo: 50,
      //       nbConfirmed:10, //bleu
      //       nbUnconfirmed:4, //jaune
      //       nbIndispo:12 //rouge
      //     }
      //   },
      //   {
      //     id: 3,
      //     name: "Jean Dupont",
      //     univers: ["Neuf", "Occasion"],
      //     annotation: "Annotation Vendeur",
      //     visioAvailable: false,
      //     stats: {
      //       nbRdv:22,
      //       nbDispo: 60,
      //       nbConfirmed:15, //bleu
      //       nbUnconfirmed:7, //jaune
      //       nbIndispo:2 //rouge
      //     }
      //   },
      // ],
    };
  },
  watch: {
    dialog(val){
      if(this.operations && this.operations.length > 0){
          if(this.operations[0].config.slot_duration ){
            this.creneau = this.operations[0].config.slot_duration
          } else {
            this.creneau = "60"
          }        
      }
    }
  },
  methods: {
    open(title) {
      this.dialog = true;
      this.title = title;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    submit(response) {
      this.resolve(response);
      this.dialog = false;
    },
    cancel(reload = false) {
      this.resolve(false);
      this.dialog = false;
    },
    addRdv(item){
      if(item.hasOwnProperty('ref_id') && item.ref_id != null){
        item.ref = this.teamPlannings.find((e) => e.id == item.ref_id)
      } else {
        //item.contact = ""
        item.ref = null
      }
      if(this.type == "ADD_RDV_REQUEST"){
        this.submit({method:'addRdv', rdv: this._.cloneDeep(item)})
      } else{
        this.submit({rdv: this._.cloneDeep(item)})
      }
      
      //this.appointements.push(this._.cloneDeep(item))
    },
    getPlanningIndispo(payload) {
      GenericDataService.postData('/operation/' + this.operationId + '/getDispos', payload).then((response) => {
          this.genericUnavailable = response.data.data.hours_reversed
      });
    },
    recordInfos(infos){
      let appointmentIndex = this.appointements.findIndex((e) => e.date == infos.date && e.start == infos.start && e.end == infos.end);
      //TODO : EVENT pour ajout du rdv
      //this.appointements[appointmentIndex] = infos
    },
  }
};
</script>

<style lang="scss">
    .v-dialog {
      overflow:hidden !important;
    }
    .globalAgenda {
        height:100%;
        overflow:hidden;
        background-color: #FFF;
        .toolbar {
            position:absolute;
            width:100%;
        }
    }
    .agendaContent {
        //padding-top:63px;
        position:relative;
        top:63px;
        //padding:15px;
        height:calc(100% - 63px);
        overflow-y:auto;
        width:100%;
    }
</style>
<template>
    <v-row class="exchangeFormComponent">
        <v-col cols="12" class="d-flex align-center">
            <div class="mr-2">
                {{ $t('type') }} :
            </div>
            <div class="mr-2">
                <v-btn-toggle v-model="canal" mandatory borderless color="white">
                    <v-btn small depressed value="EMAIL">
                        {{ $t('email') }}
                    </v-btn>
                    <v-btn small class="ml-2" depressed value="SMS">
                        {{ $t('sms') }}
                    </v-btn>
                    <v-btn small class="ml-2" depressed value="CALL">
                        {{ $t('call') }}
                    </v-btn>
                </v-btn-toggle>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="['EMAIL', 'SMS'].includes(canal) && !isMMS" id="selectTemplateButton" small color="primary" depressed @click="openLib">{{ $t('selectTemplate') }}</v-btn>
        </v-col>

        <v-col cols="12" class="input-form pb-0">
            <FormComponent v-if="form" :form="form" :action="'SET_DATA'" :key="formKey" :hideButtons="true" :noPadding="true" @removeLoader="sendButtonLoader = false" @saveForm="submit" @modelIsUpdated="modelIsUpdated" ref="formComponentExchange" />

            <v-row v-if="canal === 'CALL' && phones.length" class="mb-0">
                <v-col cols="4" v-for="(phone, index) in phones" :key="index">
                    <a :href="'tel:' + phone.number">
                        <v-card outlined @click.stop="">
                            <v-list-item>
                                <v-list-item-avatar color="primary"><v-icon small dark>$phone_d</v-icon></v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title><b>{{ phoneNumberToClean(phone.number) }}</b></v-list-item-title>
                                    <v-list-item-subtitle>{{ $t(phone.type) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </a>
                </v-col>
            </v-row>
            <v-alert v-else-if="canal === 'CALL'" border="left" color="info" text>
                {{ $t("emptyPhonesInfoMessage") }}
            </v-alert>
        </v-col>

        <v-col cols="12" class="d-flex pt-0" v-if="canal !== 'CALL'">
            <v-spacer></v-spacer>
            <v-btn color="pink" dark depressed :loading="sendButtonLoader" @click="validateForm">{{ $t("send") }}</v-btn>
        </v-col>

        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="canal" @cancel="formLib = null;" @selectModel="selectModel" :pickerForm="true" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" :isFromLibraryPage="true" :contentClasses="'fromExchangeFormComponent'" />
    </v-row>
</template>

<script>
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation';
import FormComponent from "@/components/forms/FormComponent";
import GenericDataService from '@/services/GenericDataService';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";

export default {
    name: "exchangeFormComponent",
    components: { FormComponent, LibraryDialogComponent },
    mixins:[getForm, dateManipulation],
    data() {
        return {
            isMMS: false,
            sendButtonLoader: false,
            form: null,
            formKey: 0,
            formLib: null,
            showLibraryDialog: false,
        }
    },
    watch: {
        canal: {
            immediate: true,
            handler(val) {
                if(val !== 'CALL')
                    this.getExchangeForm(val);
                else
                    this.form = null;

                
            }
        },
        contact(val) {
            if(val) {
                this.getExchangeForm(this.canal);
            }
        }
    },
    computed: {
        canal: {
            get: function () {
                return this.$store.state.exchange.canalForDrawer;
            },
            set: function (newValue) {
                this.$store.dispatch('exchange/SET_CANAL_FOR_DRAWER', { value: newValue });
            }
        },
        contact: function () {
            return this.$store.state.contact.contact;
        },
        phones: function () {
            return this.contact.phones.filter((e) => !e.blacklisted)
        },
    },
    methods: {
        getExchangeForm(canal) {
            let url = "/exchange/getForm?formName=" + canal + "&contact_id=" + this.contact.id;

            if(this.$store.state.exchange.exchangeForDrawer.id)
                url += "&exchange_id=" + this.$store.state.exchange.exchangeForDrawer.id;

            if(this.$store.state.exchange.superleadForDrawer.id)
                url += "&superlead_id=" + this.$store.state.exchange.superleadForDrawer.id;

            this.getForm(url, false, false, null, null, false);
        },
        openLib() {
            GenericDataService.getData('/library/getFilters?libType=' + this.canal + '&filters[filters.type]=' + encodeURIComponent(JSON.stringify(['CONVERSATIONAL']))).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true
            });
        },
        validateForm() {
            this.sendButtonLoader = true;
            this.$refs.formComponentExchange.validateForm(true);
        },
        modelIsUpdated(model) {
            this.$emit("modelIsUpdated", this.model);
            if(this.canal === 'SMS' && model.image && model.image.id) {
                this.isMMS = true;
            } else {
                this.isMMS = false;
            }
        },
        submit(response) {
            this.$store.dispatch('forms/CLEAR_FORM', this.form.reference);
            this.getExchangeForm(this.canal);
            this.$emit('submited', response);
        },
        openLib() {
            GenericDataService.getData('/library/getFilters?libType=' + this.canal + '&filters[filters.type]=' + encodeURIComponent(JSON.stringify(['CONVERSATIONAL']))).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true
            });
        },
        selectModel(model) {
            if(this.canal === 'SMS') {
                this.form.model.message = model.template.text;
            } else if(this.canal === 'EMAIL') {
                this.form.model.subject = model.params.subject;
                this.form.model.message = model.template.html;
            }

            this.formLib = null;
            this.showLibraryDialog = false;
            this.formKey++;
        }
    }
}
</script>

<style lang="scss">
.exchangeFormComponent {
    .v-btn-toggle > .v-btn.v-btn--active {
        background-color: var(--v-primary-base) !important;
    }

    .theme--light.v-sheet--outlined {
        border-color: var(--v-primary-base) !important;
    }
}
</style>
<template>
    <div>
        <v-list-item class="primary primarytext--text todo-header">
            <v-list-item-content class="py-0">
                <v-list-item-title class="primarytext--text d-flex align-center justify-space-between pa-3">
                    <span>{{ $t("notifications") }}</span>
                    <v-btn depressed color="primary" class="square" @click="closeModal">
                        <v-icon small dark>$close</v-icon>
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-btn-toggle 
            v-model="notifMenu" 
            mandatory 
            dense
            group
            borderless
            class="notifs-toggle mb-0 pa-4" 
            style="width: 100%">
                <v-btn color="primary" small value="1" :style="notifMenu == '1' ? 'background-color:var(--v-primary-base) !important; color:#FFF;' : 'background-color:var(--v-lightgrey-base) !important; color:#000;'" style="border-radius:20px !important;">{{ $t("all") }} </v-btn>
                <v-btn color="primary" small value="0" :style="notifMenu == '0' ? 'background-color:var(--v-primary-base) !important; color:#FFF;' : 'background-color:var(--v-lightgrey-base) !important;  color:#000;'" style="border-radius:20px !important;">{{ $t("notread") }}</v-btn>
        </v-btn-toggle>
        <v-list v-if="notifMenu == '1'" class="pa-0">
            <v-list-item v-for="(notif, index) in allNotifs" :key="'notif_'+index"  :class="(notif.lu == 0) ? 'lightgrey' : ''" @click="readNotif(notif)" style=" border-bottom: 1px solid rgba(0,0,0,0.1) !important;">
                <v-list-item-icon class="mr-2">
                    <v-icon x-small :color="notif.lu == 0 ? 'primary' : 'darkgrey'">$circle_s</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <b>{{notif.params.message ? notif.params.message : notif.params.subject}}</b>
                    <p class="mb-0 primary--text">{{$t('thereis')}} {{getChipsTime((Date.now() - new Date(notif.ts).getTime())/1000, true, true)}}</p>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list v-if="notifMenu == '0'" class="pa-0">
            <v-list-item v-for="(notif, index) in notreadNotifs" :class="notif.lu == 0 ? 'lightgrey' : ''" @click="readNotif(notif)" :key="'notif_'+index" style=" border-bottom: 1px solid rgba(0,0,0,0.1) !important;">
                <v-list-item-icon class="mr-2">
                    <v-icon x-small :color="notif.lu == 0 ? 'primary' : 'darkgrey'">$circle_s</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <b>{{notif.params.message ? notif.params.message : notif.params.subject}}</b>
                    <p class="mb-0 primary--text">{{$t('thereis')}} {{getChipsTime((Date.now() - new Date(notif.ts).getTime())/1000, true, true)}}</p>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
import getChipsTime from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"notificationComponent",
    mixins:[getChipsTime],
    data() {
        return {
            notifMenu:0 
        }
    },
    methods: {
        closeModal: function () {
            this.$store.dispatch("auth/SET_OPEN_NOTIF_DRAWER", !this.$store.state.auth.openNotifDrawer);
        },
        readNotif(notif){
            GenericDataService.getData('notif/read?id='+notif.id).then((response) => {
                notif.lu = 1;

                    if(notif.link.includes('?')){
                        let params = notif.link.split('?')[1];
                        let splittedParams = params.split('&');
                        let queryParams = []
                        splittedParams.forEach((element) => {
                            queryParams[element.split('=')[0]] = element.split('=')[1]
                        });
                        this.$router.replace({ query: queryParams }).catch(err => { });
                    }

                this.$store.dispatch("auth/SET_OPEN_NOTIF_DRAWER", !this.$store.state.auth.openNotifDrawer);
            })
        }
    },
    computed: {
        notreadNotifs(){
            return this.$store.state.auth.notifs.filter((e) => e.lu == 0)
        },
        allNotifs(){
            return this.$store.state.auth.notifs
        }
    },
}
</script>
<style lang="scss">
    .notifs-toggle {
        border-bottom: 1px solid rgba(0,0,0,0.1)
    }
</style>
<template>
    <v-app-bar height="95" dense style="position:sticky;border-bottom: 1px solid lightgrey;" class="bottom-border header" color="bg" flat min-width="100%">
        <div class="logo">
            <router-link to="/">
                <v-img :src="logo" contain class="logo-mini"></v-img>
            </router-link>
        </div>
        <!-- <SearchMenu /> -->
        <v-spacer></v-spacer>
        <UserMenu @switch="toggleMenu" />
    </v-app-bar>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import UserMenu from "@/components/UserMenu";
import SearchMenu from "@/components/SearchMenu";
export default {
    name:"GlobalHeader",
    components: {
        UserMenu,
        SearchMenu,
        BreadCrumb
    },
    data() {
        return {
            toolTitle: ""
        }
    },
    methods: {
        toggleMenu: function(){
            this.$emit('toggleMenu')
        },
    },
    computed: {
        logo: function(){
            return this.$store.state.logo
        },
        availableApps: function(){
            return this.$store.state.base.home.filter((e) => e.active && !e.tools)
        },
        availableTools: function(){
            return this.$store.state.base.home.filter((e) => e.active && e.tools)
        },
        selectedApplication : function(){
            let application = this.$store.state.base.application;
            let appObject = this.$store.state.base.home.find((e) => e.application == application)
            return appObject
        }
    },
}
</script>
<style lang="scss">

.menu-app {
    background-color: white;

}
.tools-menu {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    &:after{
        min-height:0px !important;
    }
    .tool-title {
        color: #09577d !important;
        text-transform: uppercase;
    }
}
.icon-tools {
    color:#c6c6c6 !important;
    background-color:transparent !important;
    &:hover {
        color: #09577d !important;
        background-color:transparent !important;
    }
}
.menu-header{
        text-transform: uppercase;
        color : var(--v-darkgrey-base) !important;
        min-height:35px !important;
        font-weight:bold;
    }
    .menu-item {
        min-height:40px !important;
        border-left:8px solid #AAA;
        font-size:13px;
    }
    .header {

        flex-grow:0;
        .v-toolbar__content {
            padding-left:0;
        }
        .logo {
            padding:0 60px;
            height:100%;
            max-width:270px;
            .logo-mini  {
                height:100%;
                width:auto;
                .v-responsive__content {
                    width: 270px;
                }
            }
        }
        .v-toolbar__content{
            border-bottom: 1px solid lightgrey;
        }

    }
</style>

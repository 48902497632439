<template>
    <v-card class="exchange-card">
        <v-app-bar style="cursor: pointer;" @click="closeDrawer()" flat color="primary" height="45">
            <v-toolbar-title class="white--text pl-0">{{ $t('new_message') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click.stop="closeDrawer()" depressed color="primary" class="square"><v-icon dark small>$close</v-icon></v-btn>
        </v-app-bar>

        <v-card-text class="exchange-card-text">
            <exchangeHistoryComponent :historyKey="historyKey" :class="fromBottomSheet ? 'mt-2' : ''" :isFromPopup="true" />
            <div class="py-2">
                <v-divider></v-divider>
            </div>
            <exchangeFormComponent @submited="historyKey++" @modelIsUpdated="modelIsUpdated = true" />
        </v-card-text>

        <v-dialog v-model="confirmExitDialog" max-width="700">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('checkBeforeExitFormWithoutSave') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed text class="square" @click="confirmExitDialog = false">
                        <v-icon small> $close </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-actions class="py-6">
                    <v-spacer></v-spacer>
                    <v-btn depressed color="lightgrey" @click="closeDrawer(true)">{{ $t('exitWithoutSave') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import exchangeFormComponent from "@/modules/exchanges/exchangesComponents/exchangeFormComponent";
import exchangeHistoryComponent from "@/modules/exchanges/exchangesComponents/exchangeHistoryComponent";

export default {
    name:"OperationObjectComponent",
    props: ['fromBottomSheet'],
    components: { exchangeFormComponent, exchangeHistoryComponent },
    data() {
        return {
            historyKey: 0,
            modelIsUpdated: false,
            confirmExitDialog: false,
        }
    },
    methods: {
        closeDrawer(forceExit = false) {
            if(this.modelIsUpdated && !forceExit) {
                this.confirmExitDialog = true;
            } else {
                this.$store.dispatch("contact/SET_CONTACT_DATA", {});
                this.$store.dispatch("exchange/SET_OPEN_EXCHANGE_DRAWER", { value: false, canal: 'EMAIL', exchange: {}, superlead: {} });
            }
        },
    },
    created() {},
    mounted() {},
    computed: {},
    watch: {},
    destroyed() {},
}
</script>

<style lang="scss">
    .bottom-card {
        .v-card.exchange-card {
            max-height: fit-content;
            .exchange-card-text.v-card__text {
                width: 800px;
                max-height: 90vh;
                overflow: auto;
                .v-card__text {
                    width: 100%;
                }
            }
        }
    }
</style>
<template>
    <v-card rounded="lg" :disabled="!(superlead.contact_id && superlead.contact_id.id) || formDisabled">
        <v-card-title style="border-bottom: 1px solid #ddd;">
            <h5 class="pb-0"><b>{{ $t('project_' + demand.type + '_title') }}</b></h5>
            <v-spacer></v-spacer>
            <v-btn text depressed v-if="!demand.hasOwnProperty('details.mainDemand') || !demand['details.mainDemand']" class="square" @click="$emit('removeDemand')">
                <v-icon color="error" small>$trash_d</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <FormComponent v-if="form" :form="form" :ref="'formDemandComponent_'+index" />
        </v-card-text>
    </v-card>
</template>

<script>
import FormComponent from "@/components/forms/FormComponent";
import getForm from "@/mixins/mixins";

export default {
    name: "SDemand",
    props: ["superlead", "superleadBase", "demand", "index", "formDisabled"],
    components: { FormComponent },
    mixins: [getForm],
    data() {
        return {
            form: null,
        };
    },
    watch: {
        form: {
            handler(val){
                if(val && val.model){
                    this.superlead.demands[this.index] = val.model
                    //this.superlead.contact = this._.cloneDeep(val.model)
                    this.$set(this.superlead, 'demands', this._.cloneDeep(this.superlead.demands))
                }
            },
            deep:true
        }
    },
    async created() {
        let url = "/superlead/getForm?formName=" + this.demand.type + "&operation_id=" + this.superlead.operation_id.id;

        if(this.demand.hasOwnProperty('id'))
            url += "&id=" + this.demand.id;

        if(this.superlead.contact_id && this.superlead.contact_id.id)
            url += "&postdata[contact_id]=" + this.superlead.contact_id.id;

        if(this.demand['details.mainDemand'])
            url+= "&postdata[details.mainDemand]=1";

        if(this.demand['item_id'] || this.demand['car.id'])
            url+= "&postdata[item_id]=" + (this.demand['item_id'] && this.demand['item_id'].id ? this.demand['item_id'].id : this.demand['car.id']);

        Object.keys(this.demand).forEach(key => {
            if(key !== 'contact_id' && key !== 'entity_id' && key !== 'details' && key !== 'operation_id' && key !== 'item_id' && key !== 'univers_id' && this.demand[key])
                url+= "&postdata[" + key + "]=" + (typeof this.demand[key] === "object" ? JSON.stringify(this.demand[key]) : this.demand[key]);
        });

        await this.getForm(url, false, false, null, null, false);
        this.$set(this.superleadBase, 'demands', this._.cloneDeep(this.superlead.demands))
    },
    methods: {
        validateSDemand(){
            if(this.$refs['formDemandComponent_'+this.index].validateFormPart()){
                return true
            } else {
                return false
            }
        }
    },
}
</script>

<style lang="scss">
</style>

<template>
    <v-container class="pt-0" :style="'width:'+(!stepper ? '100%' : '50%')+'; min-height:'+(!stepper ? 'unset' : '500px')+'; overflow:hidden;'">
        <v-row>
            <v-col cols="12" class="text-center" v-if="stepper && !noButton">
                <v-btn small depressed color="primary" @click="dialogHours = true">Modifier les horaires</v-btn>
            </v-col>
            <div class="iconEdit" v-else-if="!stepper && !noButton && $func.hasRight('operation/update')">
                <v-btn text depressed class="square" @click="dialogHours = true">
                    <v-icon small>$pencil_d</v-icon>
                </v-btn>
            </div>
            <v-col cols="12" class="pt-0">
                <table class="ml-auto mr-auto tableHours" :style="!stepper ? 'border-width:0 !important;': 'border-width:1px; padding-left:8px;'" v-if="Object.keys(hoursReversed).length > 0 && !weekdays">
                    <tr v-for="(days, index) in hoursReversed"  v-show='!daysdate' :key="'day_'+index">
                        <td class="pt-2 dateLabel" v-if="daysArray[index-1]"><b class="mr-3 d-block" style="padding-top:6px;">{{$t(daysArray[index-1])}}</b></td>
                        <td class="pt-2 dateLabel " v-else><b class="mr-3 pl-2">{{parseUsDateToFr(index)}}</b></td>
                        <td class="text-left pt-2" v-if="days.length > 0">
                            <v-chip outlined label small v-for="(hour, index) in days" :key="index" color="secondary" class="mr-2 my-1">
                                <span>{{hour}}</span>
                            </v-chip>
                        </td>
                        <td class="text-left pt-2" v-else>
                        <v-chip outlined label small color="secondary" class="mr-2 my-1">
                            <span>{{$t('closeLabel')}}</span>
                        </v-chip>
                        </td>
                    </tr>
                    <tr v-for="(days, index) in daysdate"  v-show='daysdate' :key="'day_'+index" v-if="hoursReversed[days]">
                        <td class="pt-2 dateLabel"><b class="mr-3 d-block" style="padding-top:6px;">{{parseUsDateToFr(index)}}</b></td>
                        <td class="text-left pt-2" v-if="hoursReversed[days].length > 0">
                            <v-chip outlined label small v-for="(hour, index) in hoursReversed[days]" :key="index" color="secondary" class="mr-2 my-1">
                                <span>{{hour}}</span>
                            </v-chip>
                        </td>
                        <td class="text-left pt-2" v-else>
                            <v-chip outlined label small color="secondary" class="mr-2 my-1">
                                <span>{{$t('closeLabel')}}</span>
                            </v-chip>
                        </td>
                    </tr>
                </table>
                <table class="ml-auto mr-auto tableHours" :style="!stepper ? 'border-width:0 !important;': 'border-width:1px;'" v-else-if="Object.keys(hoursReversed).length > 0 && weekdays">
                    <tr v-for="(days, index) in hoursReversed" :key="'day_'+index">
                        <td class="pt-2 dateLabel"><b class="mr-3 d-block" style="padding-top:6px;">{{ $t(daysArray[index-1]) }}</b></td>
                        <td class="text-left pt-2" v-if="days.length > 0">
                            <v-chip outlined label small v-for="(hour, index) in days" :key="index" color="secondary" class="mr-2 my-1">
                                <span>{{ hour }}</span>
                            </v-chip>
                        </td>
                        <td class="text-left pt-2" v-else>
                            <v-chip outlined label small color="secondary" class="mr-2 my-1">
                                <span>{{ $t('closeLabel') }}</span>
                            </v-chip>
                        </td>
                    </tr>
                </table>
                <v-alert
                    border="left"
                    class="mt-4  mb-0"
                    text
                    dense
                    color="info"
                    v-else
                    >
                    <template v-slot:prepend>
                        <v-icon color="info" class="mr-3" small>
                            $warning_s
                        </v-icon>
                    </template>
                    {{$t('noHoursFound')}}
                </v-alert>
                <v-dialog
                    v-model="dialogHours"
                    fullscreen
                    transition="dialog-bottom-transition"
                    scrollable
                    persistent
                    @keydown.esc="cancel"
                    content-class="vdialogPlanning"
                    >
                        <v-card tile>
                        <v-toolbar flat dark class="bg-gradient">
                                <v-btn
                                    depressed
                                    text
                                    color="white"
                                    class="square transparent mr-2"
                                    @click.native="cancel"
                                >
                                    <v-icon dark small>$prev</v-icon>
                                </v-btn>

                            <v-toolbar-title>{{ $t('operationHours') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn outlined class="mr-3" @click="cancel" color="white">Annuler</v-btn>
                            <v-btn outlined color="white" @click="recordHours">Enregistrer</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <Planning ref="planning" type="OPERATION_HOURS"
                                :genericUnavailable="genericUnavailable"
                                :isMine="false"
                                :creneau="60"
                                :operations="operations"
                                :appointements="[]"
                                :entityId="entityId"
                                :operationId="operationId"
                                @addItem="addItem"
                                @removeUnavailable="removeUnavailable"
                                @removeAllUnavailable="removeAllUnavailable"
                                @addAllUnavailable="addAllUnavailable"
                                mode="popup"
                                />
                        </v-card-text>
                        </v-card>
                    </v-dialog>
                    <Loader :flat="true" v-if="loading" style="height:100%;"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Planning from '@/components/planning/Planning'
import planningMixins from '@/mixins/planningMixins'
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
import Loader from '@/components/Loader'
export default {
    name:"hoursStepComponent",
    components: {Planning, Loader},
    props:['operationId', "loadData", "stepper", "noButton", "weekdays"],
    mixins:[planningMixins, dateManipulation],
    data() {
        return {
            dialogHours: false,
            publicPath:process.env.BASE_URL,
            entityId:1,
            operations: [],
            genericUnavailable: [],
            hoursReversed: {},
            loading:false,
            daysdate:null,
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true
                    GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                        this.operations = [response.data.data]
                        GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                            this.daysdate = response.data.data.dates
                            this.hoursReversed = response.data.data.hours_text2
                            this.genericUnavailable = response.data.data.hours
                            this.loading = false
                        }).catch((e) => {
                            this.loading = false
                        })
                    }).catch((e) => {
                        this.loading = false
                    })
                }
            }
        }
    },
    created() {
        GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
            this.operations = [response.data.data]
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable = response.data.data.hours
                this.loading = false
            }).catch((e) => {
                this.loading = false
            })
        }).catch((e) => {
            this.loading = false
        })
    },
    mounted() {
        GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
            this.operations = [response.data.data]
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable = response.data.data.hours
                this.loading = false
            }).catch((e) => {
                this.loading = false
            })
        }).catch((e) => {
            this.loading = false
        })
    },
    computed: {
        daysArray: function(){
            return ['mondayLabel', 'tuesdayLabel', 'wednesdayLabel', 'thursdayLabel', 'fridayLabel', 'saturdayLabel', 'sundayLabel']
        }
    },
    methods: {
        cancel: function(){
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable = response.data.data.hours
            })
            this.dialogHours = false
        },
        recordHours(){
            GenericDataService.postData('/operation/'+this.operationId+'/setHours', this.genericUnavailable).then((response) =>{
                GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                    this.$emit('refreshRetroplanningAndOpe');
                    this.hoursReversed = response.data.data.hours_text2
                    this.genericUnavailable = response.data.data.hours
                    this.dialogHours = false
                })
            })
        },
        //Ajout d'une indispo
        addItem:function(event, unavailableData) {
           //let slot = this.genericUnavailable.find((e) => e.day == unavailableData.day)
            let item = this._.cloneDeep(unavailableData);
            item.start = this.parseFloatToHour(this.parseHourToFloat(unavailableData.start))
            item.end = this.parseFloatToHour(this.parseHourToFloat(unavailableData.end))
            //check si une indispo est présente avant et apres
            let itemBefore = this.genericUnavailable.find(
                (e) => (e.day == unavailableData.day &&
                          (
                              this.parseHourToFloat(e.end) == this.parseHourToFloat(item.start) ||
                              (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                                  && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                                  && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                          )
                        )
            );
            let itemAfter = this.genericUnavailable.find(
              (e) => (e.day == unavailableData.day &&
                        (
                            this.parseHourToFloat(e.start) == this.parseHourToFloat(item.end) ||
                            (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                        )
                      )
                );
            let itemUnder = this.genericUnavailable.filter((e) => (e.day == unavailableData.day && this.parseHourToFloat(e.start) >= this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) <= this.parseHourToFloat(item.end) && e.end != "00:00"))
            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == item.end);
                itemBefore.end = itemAfter.end
                this.genericUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                itemBefore.end = item.end
            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++) {
                    let itemUnderIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == itemUnder[index].start && e.end == itemUnder[index].end)
                    this.genericUnavailable.splice(itemUnderIndex, 1)
                }
                this.genericUnavailable.push(item);
            } else { // sinon on le push
                this.genericUnavailable.push(item);
            }
        },
        //Suppression d'une indispo
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.findIndex((e) => e.day == slot.day && e.start == slot.start)
            //let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            this.genericUnavailable.splice(dayItem, 1)
        },
        removeAllUnavailable() {

        },
        addAllUnavailable(){

        }
    },
}
</script>
<style lang="scss">
    .iconEdit {
        position:absolute;
        top:20px;
        right:24px;
    }
    .tableHours {
        padding-bottom:8px;
        border: 1px solid rgba(0,0,0, 0.13)
    }
    .dateLabel{
        vertical-align: top;
        color:#000000;
    }
</style>

<template>
  <div class="todoComponent">
    <v-list-item class="primary primarytext--text todo-header">
      <v-list-item-content class="py-0">
        <v-list-item-title class="primarytext--text d-flex align-center justify-space-between">
          <span>{{ $t("myActions") }}</span>
          <v-btn depressed color="primary" class="square" @click="closeModal">
            <v-icon small dark>$close</v-icon>
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="pa-6 pt-0">
      <v-list v-if="todos && todos.length">
        <template v-for="(todo, index) in todos.slice(0, 5)">
          <div :key="index">
            <v-divider v-if="index !== 0" :inset="true"></v-divider>
            <TodoComponent :target="true" :todo="todo" :link="false" @getLink="getLink"/>
          </div>
        </template>
        <div v-if="todos.length > 5">
          <v-divider :inset="true" class="mb-3"></v-divider>
          <v-spacer></v-spacer>
          <a @click="goToList" class="filterButton float-right">
            {{ $t('see_more') }}
          </a>
        </div>
      </v-list>
      <div v-else>
        <v-alert class="mt-2 mb-0" border="left" color="info" text>
          {{ $t('emptyTodoArrayInfoMessage') }}
        </v-alert>
      </div>
    </div>

    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :form="form"
      :action="'SET_DATA'"
    />
  </div>
</template>
<script>
import TodoComponent from '@/components/TodoComponent'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';

export default {
  name: "todoComponent",
  components: {
      FormDialogComponent,
      TodoComponent
  },
  mixins:[getForm, refreshData],
  data() {
    return {
      todosMenu: 1,
      todosUnassigned: true,
      todosTypes: [],
      todosUsers: [],
      newFormUrl: "/todo/newForm",
      formUrl: "",
      form: null,
      apiUrl: "/todo/get",
      mutation: "auth/setUserTodos",
    };
  },
  mounted() {
    this.refreshData(null, this.fullApiUrl)
  },
  computed: {
    openTodoDrawer: function () {
      return this.$store.state.auth.openTodoDrawer
    },
    todos: function () {
      return this.$store.state.auth.todos
    },
    todosFilterUsers: function () {
      return this.$store.state.auth.todosFilterUsers
    },
    todosFilterTypes: function () {
      return this.$store.state.auth.todosFilterTypes
    },
    loggedUser: function () {
      return this.$store.state.auth.currentUser.loggedAs
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    fullApiUrl() {
      // return this.apiUrl + "?started=" + this.todosMenu + "&unassigned=" + this.todosUnassigned + "&types=" + this.todosTypes + "&filterUserIds=" + this.todosUsers;
      return this.apiUrl + "?started=" + this.todosMenu + "&filterUserIds=" + [this.$store.state.auth.user.id];
    },
  },
  watch: {
    openTodoDrawer (newCount, oldCount) {
      if(newCount === false) {
        this.todosMenu = 1;
      }
      this.refreshData(null, this.fullApiUrl)
    },
    todosMenu(newVal){
      this.refreshData(null, this.fullApiUrl);
    },
    todosUnassigned(newVal){
      this.refreshData(null, this.fullApiUrl);
    },
    todosTypes(newVal){
      this.refreshData(null, this.fullApiUrl);
    },
    todosUsers(newVal){
      this.refreshData(null, this.fullApiUrl);
    },
    loggedUser(newVal){
      this.refreshData(null, this.fullApiUrl);
    },
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch("auth/SET_OPEN_TODO_DRAWER", !this.$store.state.auth.openTodoDrawer);
    },
    // Récupère l'action à faire selon le type du link
    getLink(action){          
      if(action.action == "form"){
        this.getForm(action.url, false, false, this.fullApiUrl);
      } 
    },
    goToList() {
      this.closeModal();
      if(this.$route.name !== "TodosList") {
        this.$router.push({name: 'TodosList', query: {"datatableFilters": "{\"user_ids\":[\"" + this.$store.state.auth.user.id + "\"]}"}}); 
      }
    }
  },
};
</script>
<style lang="scss">
.todoComponent {
  .todo-header {
    padding: 14px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-expansion-panel::before {
    display: none;
  }
  .expand-icon {
    transform: rotate(180deg);
    &.inactive {
      transform: rotate(0deg);
    }
  }
  .filterButton {
    color: var(--v-primary-base);
    text-decoration: underline;
    .v-icon {
      color: var(--v-primary-base);
    }
  }
  .v-list {
    .v-subheader,
    .v-list-item {
      padding: 12px 0px;
      .v-list-item__content {
        overflow: visible;
        .v-list-item__subtitle {
          overflow: visible;
          .info-item {
            position: relative;
            // .v-icon {
            //   position: absolute;
            //   left: -28px;
            // }
          }
        }
      }
    }
    .v-avatar{
      margin-top:10px !important;
    }
    .v-divider--inset:not(.v-divider--vertical) {
      margin-left: 0;
      max-width: 100%;
    }
  }
  .actions-toggle {
    .v-btn.v-btn--active {
      background-color: rgba (0, 0, 0, 0.35) !important;
    }
  }
}
</style>

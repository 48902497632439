var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userMenu"},[(!_vm.$store.state.auth.currentUser.profile.is_presta)?_c('v-menu',{staticClass:"mx-1",staticStyle:{"z-index":"111"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-6 bg-gradient pa-4",attrs:{"depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":"","small":""}},[_vm._v(" $plus_l ")]),_vm._v(" "+_vm._s(_vm.$t('createANew'))+" ")],1)]}}],null,false,3814999998)},[_c('v-list',_vm._l((_vm.baseBtns),function(item,index){return (item.menuRight ? _vm.$func.hasRight(item.menuRight) : true)?_c('v-list-item',{key:index,attrs:{"dense":"","exact":"","link":""},on:{"click":function($event){return _vm.selectModule(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.label)))])],1):_vm._e()}),1)],1):_vm._e(),(_vm.loggedAs)?_c('v-chip',{staticClass:"mx-1 chips",attrs:{"close":"","color":"warning","outlined":""},on:{"click:close":function($event){return _vm.loggedOutAs()}}},[_vm._v(" "+_vm._s(_vm.$t('logged-as', _vm.loggedAs))+" ")]):_vm._e(),(this.$store.state.auth.currentUser.profile.switch_role)?_c('v-select',{staticClass:"ml-1 mr-2 header-select",attrs:{"dense":"","hide-details":"","menu-props":{ bottom: true, offsetY: true },"append-icon":"","items":_vm.availableRole,"item-text":"name","item-value":"id","no-data-text":_vm.$t('no-data-text')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"primary"}},[_vm._v("$caretdown_s")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,76936843),model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}}):_vm._e(),(_vm.availableAccount.length > 1)?_c('v-select',{staticClass:"ml-1 mr-2 header-select",attrs:{"dense":"","hide-details":"","menu-props":{ bottom: true, offsetY: true },"append-icon":"","items":_vm.availableAccount,"item-text":"name","item-value":"id","no-data-text":_vm.$t('no-data-text')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"primary"}},[_vm._v("$caretdown_s")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,76936843),model:{value:(_vm.accountId),callback:function ($$v) {_vm.accountId=$$v},expression:"accountId"}}):_vm._e(),_c('div',{staticClass:"mr-1"},[_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.openCloseModal('openNotifDrawer')}}},[(_vm.notifNb > 0)?_c('v-badge',{staticClass:"badge-nb",attrs:{"color":"error","content":_vm.notifNb,"dot":false,"overlap":""}},[_c('v-icon',{attrs:{"dark":"","color":"primary"}},[_vm._v("$bell_d")])],1):_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v("$bell_d")])],1)],1),(_vm.currentUser.profile)?_c('v-menu',{staticClass:"mx-1",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{key:_vm.$store.state.auth.avatarKey,attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.api+'/user/'+_vm.$store.state.auth.currentUser.profile.id+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true&ts='+_vm.rand}})],1)],1)]}}],null,false,2761984178)},[_c('v-list',_vm._l((_vm.menuUser),function(item,index){return _c('v-list-item',{key:index,attrs:{"exact":"","link":""},on:{"click":function($event){return _vm.launchLink(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1):_vm._e(),(_vm.formLib)?_c('LibraryDialogComponent',{attrs:{"form":_vm.formLib,"libType":_vm.libType,"showLibraryDialog":_vm.showLibraryDialog},on:{"cancel":function($event){_vm.formLib = null;},"hideLibraryDialog":function($event){_vm.showLibraryDialog = false;}}}):_vm._e(),(_vm.form)?_c('FormDialogComponent',{ref:"componentForm",attrs:{"form":_vm.form,"action":'SET_DATA'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="baseHeader" :style="{background: $vuetify.theme.themes[theme].lightgrey}">
        <GlobalHeader @toggleMenu="displayMenu"/>
        <div class="baseHeaderDiv" :style="'overflow:hidden; height: calc(100vh - 95px); '">
            <v-slide-y-transition mode="out-in" >
                <router-view :key="routerKey" />
            </v-slide-y-transition>
        </div>
        <!-- <v-navigation-drawer v-model="$store.state.auth.openTodoDrawer" absolute temporary stateless right width="450px">
            <todoComponent />
        </v-navigation-drawer> -->

        <v-navigation-drawer v-model="$store.state.auth.openNotifDrawer" absolute temporary right width="450px">
            <notificationComponent />
        </v-navigation-drawer>
        <v-navigation-drawer v-model="$store.state.history.openHistoryDrawer" fixed temporary right width="450px" @transitionend="closeHistoryDrawer">
            <historyComponent v-if="$store.state.history.openHistoryDrawer" />
        </v-navigation-drawer>
        <v-navigation-drawer stateless v-model="$store.state.auth.openTodoActionDrawer"  temporary right width="450px" absolute>
            <TodoObjectComponent v-if="$store.state.auth.openTodoActionDrawer" />
        </v-navigation-drawer>
        <v-navigation-drawer stateless v-model="$store.state.auth.openOperationDrawer"  temporary right width="450px" absolute>
            <OperationObjectComponent v-if="$store.state.auth.openOperationDrawer" />
        </v-navigation-drawer>
        <v-dialog v-model="dialogReaffectVendors" fullscreen scrollable>
            <reaffectVendorsComponent @closeModal="closeReaffectVendors" :rpId="$store.state.campaign.reaffectVendorRp"/>
        </v-dialog>
        <v-dialog v-model="dialogSuperlead" fullscreen scrollable persistent :no-click-animation="true">
            <superleadComponent v-if="dialogSuperlead" @reloadRouter="reloadRouter" />
        </v-dialog>
        <v-dialog v-model="dialogPlanningUserIndispo" fullscreen scrollable transition="dialog-bottom-transition">
            <v-card rounded="lg">
                <v-toolbar tile dark class="bg-gradient">
                    <v-btn
                        depressed
                        color="white"
                        class="square mr-2"
                        text
                        @click.native="closeRecordUserIndispo"
                    >
                        <v-icon dark small>$prev</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t('userIndispoTitle')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                     <v-btn depressed outlined color="white" class="mr-3" @click="closeRecordUserIndispo">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" :loading="userIndispoButton" @click="recordUserIndispo()">
                        {{$t('recordRdvLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-0">
                    <Planning ref="planning_indispo"
                    :type="$func.hasRight('profile/is_coach') ? 'INDISPO_COACH' : 'INDISPO_USER'"
                    :key="planningKey"
                    :genericUnavailable="userUnavailable"
                    :isMine="false"
                    :vendeurId="this.$store.state.auth.currentUser.profile.id"
                    :entityId="this.$store.state.auth.currentUser.profile.entity_id"
                    :creneau="60"
                    :operations="[]"
                    :appointements="[]"
                    @addItem="addItem"
                    @editItem="editItem"
                    @removeUnavailable="removeUnavailable"
                    @deleteUserIndispo="deleteUserIndispo"
                    @getPlanningIndispo="getUserIndispo"
                    mode="popup"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <div class="bottom-card">
            <v-card class="script-card" v-if="$store.state.auth.openCallScriptDrawer && script.name">
                <v-app-bar style="cursor: pointer;" @click="closeCallScriptFullDrawer" flat color="primary" height="45">
                    <v-toolbar-title class="white--text pl-0">{{script.name}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="closeCallScriptFullDrawer" depressed color="primary" class="square"><v-icon dark small>$minus_r</v-icon></v-btn>
                    <v-btn @click.stop="closeCallScriptDrawer" depressed color="primary" class="square"><v-icon dark small>$close</v-icon></v-btn>
                </v-app-bar>

                <v-card-text v-show="$store.state.auth.openCallScriptFullDrawer">
                    <div class="script" v-html="script.template_compiled.text"></div>
                </v-card-text>
            </v-card>
            
            <v-card class="diabolocom-card" v-if="$func.hasRight('appeal/diabolocom')">
                <v-app-bar style="cursor: pointer;" @click="closeDiabolocomFullDrawer" flat color="primary" height="45">
                    <v-toolbar-title class="white--text pl-0">Diabolocom</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="closeDiabolocomFullDrawer" depressed color="primary" class="square"><v-icon dark small>$minus_r</v-icon></v-btn>
                </v-app-bar>

                <Diabolocom v-show="$store.state.auth.openDiabolocomFullDrawer"></Diabolocom>
            </v-card>

        </div>

        <v-bottom-sheet :retain-focus="false" scrollable width="700" v-model="$store.state.exchange.openExchangeDrawer" :content-class="'contentBottom'">
            <ExchangeComponent v-if="$store.state.exchange.openExchangeDrawer" :fromBottomSheet="true"/>
        </v-bottom-sheet>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>
<script>
import Diabolocom from '@/components/Diabolocom'
import GlobalHeader from '@/components/GlobalHeader'
import Loader from '@/components/Loader'
import todoComponent from '@/modules/users/usersComponents/todoComponent'
import notificationComponent from '@/components/notificationComponent'
import historyComponent from "@/components/historyComponent";
import GenericDataService from '@/services/GenericDataService'
import Planning from '@/components/planning/Planning'
import planningMixins from '@/mixins/planningMixins'
import dateManipulation from '@/mixins/dateManipulation'
import TodoObjectComponent from '@/components/TodoObjectComponent'
import OperationObjectComponent from '@/components/OperationObjectComponent'
import ExchangeComponent from '@/components/ExchangeComponent'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import reaffectVendorsComponent from '../../../components/reaffectVendorsComponent.vue';
import superleadComponent from '../../../components/superleadComponent.vue';
import getForm from "@/mixins/mixins";

export default {
    name: "BaseHeader",
    components: {GlobalHeader, todoComponent, Loader, historyComponent, Planning, notificationComponent, TodoObjectComponent, OperationObjectComponent, FormDialogComponent, reaffectVendorsComponent, Diabolocom, superleadComponent, ExchangeComponent},
    mixins:[planningMixins, dateManipulation, getForm],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            mini: true,
            userIndispoButton:false,
            userUnavailable: [],
            toSave:[],
            form: null,
            planningKey:0,
            routerKey: 0,
        }
    },
    watch: {
        routerKey(newVal){
        }
    },
    computed: {
        theme() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
        userLogged() {
            return this.$store.state.auth.currentUser;
        },
        userId() {
            return this.$store.state.auth.user.id;
        },
        currRoute() {
            return this.$router.currentRoute
        },
        dialogPlanningUserIndispo: function(){
            return this.$store.state.agenda.openIndispoDialog
        },
        dialogReaffectVendors: function() {
            return this.$store.state.campaign.reaffectVendorDialog
        },
        dialogSuperlead: {
            get() {
                return this.$store.state.lead.superleadDialog
            },
            set(newValue) {
                this.$store.dispatch("lead/CLOSE_SUPERLEAD_DIALOG", false);
            }
        },
        dialogNeedHelp: function(){
            return this.$store.state.auth.openNeedHelpDialog
        },
        todoId() {
            return this.$store.state.auth.todoIdForCallScriptDrawer;
        },
        rpId() {
            return this.$store.state.auth.rpIdForCallScriptDrawer;
        },
        script() {
            return this.$store.state.auth.templateForCallScriptDrawer;
        }
    },
    methods: {
        reloadRouter(){
            this.routerKey++
        },
        recordUserIndispo() {
            GenericDataService.postData('/user/'+this.$store.state.auth.user.id+'/setHours', this.toSave).then((response) => {
                let payload = {
                    start: this.planningStartDate,
                    nbdays: this.planningNbDays,
                    entity_id: this.$store.state.auth.user.entity_id
                }
                GenericDataService.postData('/user/'+this.$store.state.auth.user.id+'/getHours', payload).then((response) => {
                    this.toSave = []
                    this.genericUnavailable = response.data.data.slots
                    this.userUnavailable = response.data.data.slots
                    this.$store.dispatch("agenda/SET_STARTDATE_AGENDA", null);
                    this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", false);
                })
            })
        },
        deleteUserIndispo(slot){
            if(slot.id){
                slot.type = "delete"
                this.toSave.push(slot)
            } else {
                let itemToDelete = this.toSave.find((e) => (e.day == slot.day &&  e.start == slot.start))
                itemToDelete.type = "delete"
            }
            let dayItem = this.userUnavailable.findIndex((e) => (e.day == slot.day &&  e.start == slot.start))
            this.userUnavailable.splice(dayItem, 1)
        },
        getUserIndispo(payload){
            console.log("getUserIndispo")
            this.userStartDate = payload.from
            this.userNbDays = payload.nbdays
            let payload2 = {
                start: payload.from,
                nbdays: payload.nbdays,
                entity_id: this.$store.state.auth.user.entity_id
            }
            GenericDataService.postData('/user/'+this.$store.state.auth.user.id+'/getHours', payload2).then((response) => {
                this.userUnavailable = response.data.data.slots.concat(this.toSave)
            })
        },
        editItem(event, itemData){
            let itemIndex = this.userUnavailable.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.userUnavailable.splice(itemIndex, 1)
            this.userUnavailable.push(itemData)

            let toSaveIndex = this.toSave.findIndex((e) => e.start == itemData.start && e.end == itemData.end && e.id == itemData.id)
            this.toSave.splice(toSaveIndex, 1)
            this.toSave.push(itemData)
        },
        //Ajout d'une indispo utilisateur
        addItem:function(event, unavailableData) {
            let slot = this.userUnavailable.find((e) => e.date == unavailableData.date)
            let item = this._.cloneDeep(unavailableData);
            //check si une indispo est présente avant et apres
            let itemBefore = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.end == item.start ||
                (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                )
            );
            let itemAfter = this.userUnavailable.find((e) => e.date == unavailableData.date && item.source == e.source && e.type == item.type &&
                (e.start == item.end ||
                    (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                    && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                )
            );
            let itemUnder = this.userUnavailable.filter((e) => e.date == unavailableData.date && this.parseHourToFloat(e.start) > this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) < this.parseHourToFloat(item.end) && e.end != "24:00" && item.source == e.source && e.type == item.type && e.value == item.value)
            //check si on a deja préenregistré ce slot => on le supprime pour mieux le remettre
            // if(unavailableData.id) {
            //     let toSplice = this.toSave.findIndex((e)=> e.id == unavailableData.id)
            //     if(toSplice){
            //         this.toSave.splice(toSplice, 1)
            //     }
            // }

            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                itemBefore.end = itemAfter.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }
                if(itemAfter.id) {
                    itemAfter.type = "delete"
                    this.toSave.push(itemAfter)
                } else {
                    let itemAfterToSaveIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == item.end && item.source == e.source && e.type == item.type);
                    this.toSave.splice(itemAfterToSaveIndex, 1)
                }
                this.userUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                //let itemBeforeIndex = this.toSave
                itemBefore.end = item.end
                if(itemBefore.id){
                    this.toSave.push(itemBefore)
                }

            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
                if(itemAfter.id){
                    this.toSave.push(itemAfter)
                }
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++){
                    let itemUnderIndex = this.userUnavailable.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                    if(itemUnder[index].id){
                        itemUnder[index].type = "delete"
                        this.toSave.push(itemUnder[index])
                    } else{
                        let toSaveItemUnderIndex = this.toSave.findIndex((e) => e.date == unavailableData.date && e.start == itemUnder[index].start && e.end == itemUnder[index].end && itemUnder[index].source == e.source && e.type == itemUnder[index].type && e.value == itemUnder[index].value)
                        this.toSave.splice(toSaveItemUnderIndex, 1)
                    }
                    this.userUnavailable.splice(itemUnderIndex, 1)
                }
                this.userUnavailable.push(item);
                this.toSave.push(item)
            } else { // sinon on le push
                this.userUnavailable.push(item);
                this.toSave.push(item)
            }
        },
        //Suppression d'une indispo -------------------------------------------------------------- TOBEREDONE
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.find((e) => e.day == slot.day)
            let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            dayItem.slots.splice(slotIndex, 1)
        },
        closeHistoryDrawer(event) {
            if(event.propertyName == "transform" && !this.$store.state.history.openHistoryDrawer) {
                this.$store.dispatch("history/SET_API_URL", "");
                this.$store.dispatch("history/SET_HISTORY", {});
            }
        },
        displayMenu: function(){
            this.mini = false
            this.$store.dispatch('settings/SET_PARAMETERS_DATATABLE', { key:"menu_toggle", data:this.mini }).then((e) => {
                this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
            })
        },
        closeRecordUserIndispo() {
            this.toSave = []
            let payload = {
                start: this.userStartDate,
                nbdays: this.userNbDays,
                entity_id: this.$store.state.auth.user.entity_id
            }
            GenericDataService.postData('/user/'+this.$store.state.auth.user.id+'/getHours', payload).then((response) => {
                this.userUnavailable = response.data.data.slots
                this.$store.dispatch("agenda/SET_STARTDATE_AGENDA", null);
                this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", false);
            })
        },
        closeCallScriptDrawer() {
            this.$store.dispatch("auth/SET_OPEN_CALL_SCRIPT_DRAWER", { value: false, todo: {} });
        },
        closeCallScriptFullDrawer() {
            this.$store.dispatch("auth/SET_OPEN_CALL_SCRIPT_FULL_DRAWER", !this.$store.state.auth.openCallScriptFullDrawer);
        },
        closeDiabolocomFullDrawer() {
            this.$store.dispatch("auth/SET_OPEN_DIABOLOCOM_FULL_DRAWER", !this.$store.state.auth.openDiabolocomFullDrawer);
        },
        closeReaffectVendors() {
            this.$store.dispatch("campaign/CLOSE_REAFFECT_VENDOR", false);
        }
    },
    created() {
        this.$store.dispatch('settings/SET_LANGS');
        if(this.$store.state.auth.currentUser && this.$store.state.auth.currentUser.parameters && this.$store.state.auth.currentUser.parameters["menu_toggle"]) {
            this.mini = this.$store.state.auth.currentUser.parameters["menu_toggle"]
        }

        if(this.$route.query && this.$route.query.superlead) {
            GenericDataService.getData('superlead/' + this.$route.query.superlead + '/get').then((response) => {
                this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                let newQuery = this._.cloneDeep(this.$route.query);
                delete newQuery.superlead;
                this.$router.replace({'query': newQuery});
            });
        }   

        if(this.$route.query && this.$route.query.account_id) {
            let newQuery = this._.cloneDeep(this.$route.query);
            delete newQuery.account_id;
            this.$router.replace({'query': newQuery});
        }       
    },
    mounted() {
        if(this.$store.state.base.application && this.$store.state.base.application != ""){
            let app =  this.$store.state.base.home.find((e) => e.application ==  this.$store.state.base.application)
            this.$vuetify.theme.themes.light.primary = { base: `${app.color}` }
            this.$vuetify.theme.themes.dark.primary = { base: `${app.color}` }
            this.$vuetify.theme.defaults.light.primary = { base: `${app.color}` }
            this.$vuetify.theme.defaults.dark.primary = { base: `${app.color}` }
        }
    },
    
    watch: {
        userLogged: function(newVal, oldVal){
            if(newVal.parameters) {
                this.mini = newVal.parameters.menu_toggle
            }
        },
        currRoute: function(newVal, oldVal){
        },
        dialogNeedHelp: function(newVal){
            if(newVal) { 
                this.$store.dispatch("auth/SET_OPEN_NEED_HELP_DIALOG", false);
                this.getForm('user/' + this.$store.state.auth.currentUser.profile.id + '/getForm?formName=need_help');
            }
        },
        userId(val){
            if(val)
                this.planningKey++;
        },
        '$route' (to, from) {
            if(to.query && to.query.superlead) {
                GenericDataService.getData('superlead/' + to.query.superlead + '/get').then((response) => {
                    this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                    let newQuery = this._.cloneDeep(to.query);
                    delete newQuery.superlead;
                    this.$router.replace({'query': newQuery});
                });
            }
            if(to.query && to.query.retroplanning) {
                GenericDataService.getData('/retroplanning/' + to.query.retroplanning + '/get').then((response) => {
                    this.$router.push({ name: 'OperationCampaignDetailDashboard', params: { id: to.query.retroplanning, operation_id: response.data.data.operation_id.id } });
                });
            }
        },
        todoId: function(newVal, oldVal){
            if(newVal) {
                GenericDataService.getData("todo/" + newVal + "/getCallScript").then((response) => {
                    this.$store.dispatch("auth/SET_TEMPLATE_FOR_CALL_SCRIPT_DRAWER", response.data.data);
                });
            }
        },
        rpId: function(newVal, oldVal){
            if(newVal) {
                GenericDataService.getData("retroplanning/" + newVal + "/getCallScript?contact_id=" + this.$route.params.contact_id).then((response) => {
                    this.$store.dispatch("auth/SET_TEMPLATE_FOR_CALL_SCRIPT_DRAWER", response.data.data);
                });
            }
        },
    },
}
</script>

<style lang="scss">
    .contentBottom {
        position:absolute;
        right: 50px;
    }
    .bottom-card {
        position: fixed;
        bottom: 0;
        right: 13px;
        z-index: 1000;
        display: flex;
        .v-card {
            margin-top: auto;
            margin-left: 10px;
            margin-right: 10px;
            max-height: 500px;
            min-width: 200px;
        }
        .v-card__text,
        iframe {
            width: 500px;
        }
        .script-card {
            &.v-sheet.v-card:not(.v-sheet--outlined), .v-expansion-panel::before {
                box-shadow: 0 0 15px rgba(0,0,0,0.11) !important;
            }
            div.script {
                background-color: var(--v-lightgrey-base);
                padding: 10px;
                border-radius: 3px;
            }
            .v-card__text {
                height: 440px;
                overflow: scroll;
            }
        }
    }
</style>
<template>
    <v-dialog content-class="dialogItemCreate" scrollable persistent v-model="showGlobalAddDialog">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center;">
                    <v-btn x-large depressed color="transparent" style="position:absolute;" class="mr-2" @click.native="$emit('closeGlobalAddDialog')"><v-icon dark small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                    <v-toolbar-title class="d-flex align-center justify-center toolbarTitle">{{$t('createANew')}}</v-toolbar-title>
                </div> 
            </v-toolbar>
            <v-card-text>
                <div v-if="moduleBtn.length == 0" class="d-flex flex-wrap pa-12 justify-center">
                    <template v-for="(btn, index) in baseBtns">
                        <v-card :class="(btn.hover ? 'hover' : '')+' cardBtn mx-2 my-2'" @click="selectModule(btn)" @mouseover="btn.hover = true" @mouseout="btn.hover = false" v-if="btn.menuRight ? $func.hasRight(btn.menuRight) : true">
                            <v-card-text class="d-flex justify-center " style="height:100%;">
                                <div class="d-flex flex-column justify-space-between">
                                    <v-img width="150" height="100" class="flex-grow-0" contain :src="(btn.icon && btn.icon != '' ? (`${publicPath}` + 'img/' + btn.icon) : default_icon)"></v-img>
                                    <h4>{{$t(btn.label)}}</h4>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                    
                </div>
                <div v-else class="d-flex flex-wrap pa-12">
                    <v-card class="cardBtn mx-2" v-for="(btn, index) in moduleBtn" :key="'btn_'+btn.name" @click="openForm(btn)">
                        <v-card-text class="d-flex justify-center ">
                            <div>
                                <v-img width="150" height="150" contain :src="(btn.url && btn.url != '' ? btn.url : default_icon)"></v-img>
                                <h4>{{$t(btn.name)}}</h4>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
        </v-card>

        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";

import getForm from "@/mixins/mixins";

export default {
    name:"addContactItemDialogComponent",
    props: ['showGlobalAddDialog'],
    components:{FormDialogComponent, LibraryDialogComponent},
    mixins:[getForm],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            form: null,
            formLib: null,
            libType: "",
            showLibraryDialog: false,
            default_icon: "https://cdn.media.upyourbizz.com/data/DEMO/6b/ba/c9/df6e5d6753-168180845811.png",
            baseBtns: [
            ],
            moduleBtn: [],
            moduleName: "",
        }
    },
    created() {
        GenericDataService.getData('library/getLibTypes').then((response) => {
            let libTypes = Object.values(response.data.data)
            this.baseBtns = this.baseBtns.concat(libTypes.filter((e) => !e.hideInMenu))
        });
    },
    methods: {
        selectModule(btn){
            this.moduleName = btn.moduleName

            if(btn.selectUrl) {
                GenericDataService.getData(btn.selectUrl).then((response) => {
                    this.moduleBtn = response.data.data;
                });
            } else if (btn.form) {
                this.getForm(btn.form);
                this.$emit("hideGlobalAddDialog");
            } else if (btn.openLibraryDialog) {
                GenericDataService.getData("/library/getFilters?libType=" + btn.openLibraryDialog).then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    this.libType = btn.openLibraryDialog;
                    this.$emit("hideGlobalAddDialog");
                });
            }
        },
        customRefresh(response){
            if(this.moduleName == 'demand')
                this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
            if(this.moduleName == 'operation')
                this.$router.push({name:"OperationDetail", params: { operation_id: response.data.data.id }});

            this.moduleName = "";
            this.moduleBtn = [];
            this.$emit('closeGlobalAddDialog');
        },
        openForm(btn) {
            this.moduleName = "";
            this.moduleBtn = [];
            this.$emit("hideGlobalAddDialog");
        },
    },
}
</script>

<style lang="scss">
    .dialogItemCreate {
        width:unset !important;
        max-width:1150px !important;
        min-width: 500px !important;
    }
    .toolbarTitle {
        width:100%;
    }
    .cardBtn {
        max-width:180px !important;
        border:1px solid #FFF;
        &.hover {
            border:1px solid var(--v-purple-base);
        }
        h4 {
            border-bottom:0;
            text-transform: none;
            text-align: center;
            font-size:18px;
        }
        
    }
</style>
<template>
    <v-card rounded="lg" style=" height:0; min-height:100%;">
        
        <v-card-text v-if="newHistory.length > 0" style="height:100%;" class="d-flex align-center justify-space-between">
            <v-icon color="pink">$clockrotateleft_d</v-icon>
            <div style="flex-grow:1;" class="mx-4">
               <h5 class="mb-0 pb-1" style="font-size:16px; font-weight:bolder;"> {{ $t(newHistory[0].action) }} 
                <span v-if="newHistory[0].data.title">
                    <span v-if="newHistory[0].data.title.type && newHistory[0].data.title.type == 'date'">
                        ({{parseFRDateToDayMonthAndHours(newHistory[0].data.title.content)}})
                    </span>
                </span>
                </h5>
                <p v-if="newHistory[0].data.subtitle" class="mb-3">
                    <span v-if="newHistory[0].data.subtitle.type && newHistory[0].data.subtitle.type == 'date'">
                        {{parseFRDateToDayMonthAndHours(newHistory[0].data.subtitle.content)}}
                    </span>
                    <span v-else-if="newHistory[0].data.subtitle.content">
                        {{ $t(newHistory[0].data.subtitle.content) }}
                    </span>
                    <span v-else-if="newHistory[0].data.subtitle">
                        {{  newHistory[0].data.to_trad ? $t(newHistory[0].data.subtitle) : newHistory[0].data.subtitle }}
                    </span>
                </p>
                <span v-if="newHistory[0].user_name && newHistory[0].user_name == 'LANDING PAGE' " class="itemBy"> {{  $t('the')+" "+parseFullDateToFr(parseFullJsDateToUs( newHistory[0].ts ))+ " " +$t('by') +' Lexxy ' }}</span>
                <span v-if="newHistory[0].user_name && newHistory[0].user_name != 'LANDING PAGE'" class="itemBy">{{ $t('the')+" "+parseFullDateToFr(parseFullJsDateToUs( newHistory[0].ts ))+ " " +$t('by') +' '+ newHistory[0].user_name }}</span>
            </div>
            <v-btn small depressed color="purple" dark @click.native=" detailHistory = true">{{$t('seeAll')}}</v-btn>
        </v-card-text>
        <v-card-text v-else>
            <v-alert class="mb-0 mx-4 mb-2" border="left" color="info" text>
                {{ $t("noHistoryAvailable") }}
            </v-alert>
            <div></div>
        </v-card-text>
        <v-dialog v-model="detailHistory" scrollable width="900">
            <v-card rounded="lg">
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                        <v-btn depressed color="white" class="square mr-2" text @click.native="detailHistory = false">
                            <v-icon dark small>$prev</v-icon>
                        </v-btn>
                        <div class="d-flex align-center" v-if="superlead && superlead.id">
                            <v-toolbar-title class="d-flex align-center">{{$t("leadHistory")}} : #S{{ superlead.id }}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native=" detailHistory = false">{{  $t('back') }}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text>
                    <v-simple-table fixed-header height="700px">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        {{$t('date')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('user')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('action')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('detail')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in newHistory"
                                :key="item.ts+'_'+item.module_id"
                                >
                                    <td>{{ parseFullDateToFr(parseFullJsDateToUs(item.ts)) }}</td>
                                    <td>{{ item.user_name }}</td>
                                    <td>
                                        {{ $t(item.action) }}
                                        <span v-if="item.data.title">
                                            <span v-if="item.data.title.type && item.data.title.type == 'date'">
                                                ({{parseFRDateToDayMonthAndHours(item.data.title.content)}})
                                            </span>
                                            <span v-else-if="item.data.title.content">
                                                ({{ $t(item.data.title.content) }})
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.data.subtitle">
                                            <span v-if="item.data.subtitle.type && item.data.subtitle.type == 'date'">
                                                {{parseFRDateToDayMonthAndHours(item.data.subtitle.content)}}
                                            </span>
                                            <span v-else-if="item.data.subtitle.content">
                                                {{ $t(item.data.subtitle.content) }}
                                            </span>
                                            <span v-else-if="item.data.subtitle">
                                                {{ item.data.to_trad ? $t(item.data.subtitle) : item.data.subtitle }}
                                            </span>
                                        
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name: "SHistory",
    props: ["superlead"],
    components: {  },
    mixins: [dateManipulation],
    data() {
        return {
            form: null,
            history: [],
            filters:{
                period: {
                    start: "",
                    end: ""
                },
                cat: [],

            },
            pageNum:0,
            newHistory: [],
            detailHistory:false
        };
    },
    created() {
        this.refreshHistory()
    },
    watch: {
    //    superlead: {
    //         handler(val, old){
    //             //rechargement historique
    //             this.refreshHistory()
    //         },
    //         deep:true,
    //         immediate:true
    //    },
       newHistory(newVal){
        this.countReport();
       }
    },
    computed: {
        contact: function(){
            if(this.superlead && this.superlead.contact_id)
                return this.superlead.contact_id;
            else 
                return ""
        }
    },
    methods: {
       refreshHistory(){
        if(this.superlead.contact_id && this.superlead.contact_id.id){
            let modules = [
            ];
            this.superlead.demands.forEach(element => {
                if(element.id){
                    let obj = {
                        "demand": element.id
                    }
                    modules.push(obj)
                }
            });
            if(this.superlead.rdv && this.superlead.rdv.id)
                modules.push({"rdv": this.superlead.rdv.id});
            if(this.superlead.id)
                modules.push({"superlead": this.superlead.id})
            this.filters.module = modules
            GenericDataService.postData("/contact/" + this.superlead.contact_id.id + "/getActivityLog?pageNum="+this.pageNum+"&pageLen=99", {filters : this.filters}).then((response) => {
                this.history = response.data.data.log
                this.cleanHistory()
                this.totalItems = response.data.data.totalItems
                this.nbPage = response.data.data.nbOfPages
                this.loading = false
            })
        }
        
       },
       cleanHistory(){
        this.newHistory = [];

            for (const date in this.history) {
                    const historydate = this.history[date];
                    historydate.forEach(element => {
                        if(element.data && element.data.delta && element.data.delta.new){
                            let toDelete = true
                            for (const key in element.data.delta.new) {
                                if (!key.includes("__")) {
                                    toDelete = false                            
                                }
                            }
                            if(toDelete)
                                delete element.data.delta.new
                        }
                        this.newHistory.push(element);

                    });
            }
            
        },
        countReport(){
            let reportActions = this.newHistory.filter((e) => e.action == 'report_lead');
            this.$emit('changeReportNumber', reportActions.length);
        }
    },
}
</script>

<style lang="scss">
</style>

<template>
    <iframe :src="this.urlIframe + '/app/agent-crm'" allow="microphone; camera" id="iframe-diabolocom" name="diabolocom-cti-iframe" width="100%" height="435"></iframe>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "Diabolocom",
    data() {
        return {
            urlIframe: "https://fr2.engage.diabolocom.com"
        }
    },
    methods: {
        receiveMessage(e) {
            if (e.origin !== this.urlIframe)
                return;

            // Réception d'un appel
            if (e.data.event === "inboundCall" || e.data.event === "manualOutboundCall" || e.data.event === "campaignOutboundCall") {
                this.$store.dispatch("auth/SET_OPEN_DIABOLOCOM_FULL_DRAWER", true);
            }

            // Début d'un appel
            if (e.data.event === "campaignOutboundCall" || e.data.event === "conversationStart") {
                let scriptRp, contactId, superleadId;
                // Récupération du contact et du script d'appel via les customs fields
                if (e.data.data.customFields && e.data.data.customFields.contactId) {
                    contactId = e.data.data.customFields.contactId;
                }
                if (e.data.data.customFields && e.data.data.customFields.superleadId) {
                    superleadId = e.data.data.customFields.superleadId;
                }
                if (e.data.data.customFields && e.data.data.customFields.url) {
                    scriptRp = e.data.data.customFields.url.split("script_rp=")[1];
                }
                
                if (contactId || superleadId) {
                    if (superleadId) {
                        this.$router.replace({ 'query': {'superlead': response.data.data.superlead_id} }).catch(err => { });
                    } else {
                        if (scriptRp) {
                            this.$router.push({ name: 'ContactDetail', params: { contact_id: contactId }, query: {script_rp: scriptRp} })
                        } else {
                            this.$router.push({ name: 'ContactDetail', params: { contact_id: contactId } })
                        }
                    }
                } else {
                    GenericDataService.postData("search/searchPhoneCall", {phone: e.data.data.contactNumber}).then((response) => {
                        if (response.data.data.superlead_id)
                            this.$router.replace({ 'query': {'superlead': response.data.data.superlead_id} }).catch(err => { });
                    })
                }
            }
            
            // Fin d'appel
            if (e.data.event === "callEnd") {
                this.$store.dispatch("auth/SET_OPEN_DIABOLOCOM_FULL_DRAWER", true);

                // Si c'est un transfert
                if (e.data.data.reason === "TransferConfirm") {
                }
            }
        },
        callDiabolocom(number) {
            window.frames['diabolocom-cti-iframe'].postMessage({event: "dialNumber", data: {destinationNumber: number }}, this.urlIframe);
        }
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage)
    },
}
</script>
<style lang="scss">
    #iframe-diabolocom {
        border: none;
    }
</style>
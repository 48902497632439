<template>
    <div class="todoComponent">
        <v-list-item :link="true" @click="openTodoActionDrawer" replace :class="'btnTodo '">
              <v-list-item-avatar class='d-flex justify-center' :color="todo.icon_color">
                <v-icon class="iconTodo" small color="white">
                  {{ todo.icon }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="d-flex align-center">
                <div style="max-width:60%;">
                    <v-list-item-title>
                    <div class="d-flex flex-row" style="align-items:center;">
                        <div>
                        <p class="subtitle">{{ $t(todo.type) }}</p>
                        </div>
                        <v-spacer></v-spacer>
                    </div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                    <div class="d-flex info-item">
                        <p class="mb-0" v-if="todo && todo.user_link && todo.user_link.title">{{ $t('by') }} {{ todo.user_link.title }}</p>
                    </div>
                    </v-list-item-subtitle>
                </div>
                
                <div style="max-width:40%;" class="text-right">
                    <v-chip small :color="todo.due_in_seconds.value >= 0 ? todo.due_in_seconds.value > 86400 ? 'success' : 'warning' : 'error'">
                        <span v-if="todo.due_in_seconds.value < 0">{{ $t('tolate') }}</span>
                        <span v-if="todo.due_in_seconds.value <= 86400 && todo.due_in_seconds.value >= 0">{{getHoursMinutesLeft(todo.due_in_seconds.value)}}</span>
                        <span v-if="todo.due_in_seconds.value > 86400">{{ getAfterOneDay(todo.due_in_seconds.value) }}</span>
                    </v-chip>
                    <v-btn fab depressed x-small class="ml-3">
                        <v-icon x-small>
                            $next
                        </v-icon>
                    </v-btn>
                </div>
              </v-list-item-content>
        </v-list-item>
    </div>
</template>
<script>
import getChipsTime from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService'
export default {
    name:'TodoComponent',
    props:['todo', 'link', 'target'],
    mixins:[getChipsTime],
    data() {
      return {
        operation:{},
        hover: false,
      }
    },
    created() {
      GenericDataService.getData('/todo/getOperation?id=' + this.todo.id).then((response) => {
        this.operation = response.data.data
      })
    },
    methods: {
        test() {
          if(this.todo.links !== null && this.todo.links.title && this.todo.links.name && this.todo.links.name != this.$route.name){
            this.$router.push(this.todo.links)
          }
        },
        onRoute(link){
          return true
          if((link.contact && this.$route.name == 'ContactDetail' && (link.contact.params.contact_id == this.$route.params.contact_id)) || (!link.contact && link.demand && this.$route.name == 'ContactDetail' && (link.demand.params.contact_id == this.$route.params.contact_id))){
            if(link.user) {
              return true
            }
            return false
          } else {
            return true
          }
        },
        openTodoActionDrawer(){
            // Si c'est un Lead à traiter alors on ouvre directement le superlead
            if(["LEAD_TO_DEAL", "RDV_TO_CONFIRM", "FEEDBACK_RDV", "FEEDBACK_PASSAGE", "FEEDBACK_INSCRIPTION"].includes(this.todo.type)) {
                let url = "superlead/getByRdvId?id=" + this.todo.module_id;
                if(this.todo.module === 'demand')
                    url = "superlead/getByDemandId?id=" + this.todo.module_id;

                GenericDataService.getData(url + ('&operation_id=' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id))).then((response)=> {
                    this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                });
            } else {
                GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + this.todo.id).then((response) => {
                    this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: this.todo, excerpt : response.data.data});
                });   
            }
        },
        getLink(link){
          if(link.contact){
            return link.contact[0]
          }
          if(link.demand){
            return link.demand[0]
          }
          if(link.user){
            return link.user[0]
          }
          if(link.operation){
            return link.operation[0]
          }
          if(link.retroplanning){
            return link.retroplanning[0]
          }
        }
    },
}
</script>
<style lang="scss">
    .todoComponent {
      position:relative;
      .menuTodo {
        position: absolute;
        right:10px;
        top:10px;
        z-index:5;
      }
      // .v-list-item {
      //   &::before {
      //     background-color: #FFF;
          
      //   }
      //   &:hover {
            
      //       &::before {
      //         background-color: rgba(0,0,0,0.87);
              
      //       }
      //   }
      // }
      .iconTodo {
       // border: 2px solid var(--v-primary-base) !important;
      }
      h3 {
        line-height: normal;
      }
    }
    .btnTodo {
      &::before{
        background-color:transparent !important;
      }
      .v-btn--fab.v-size--x-small {
          width: 25px !important;
          height:25px !important;
      }
      &.active{
        &::before{
          background-color:#000 !important;
        }
      }
    }
</style>
<template>
    <div class="todoDrawer">
        <v-list-item class=" primary--text pa-5 py-2 pt-8">
            <v-list-item-content class="py-0">
                <v-list-item-title class="primary--text d-flex align-center justify-space-between">
                <h4 class="" style="text-transform: none; border-bottom:0; font-size:20px; min-height:unset;">{{$t(todo.type)}}</h4>
                <v-btn text depressed color="primary" class="square" @click="closeModal">
                    <v-icon  dark>$close</v-icon>
                </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <div class="pa-4 pt-0">
            <div class="d-flex mt-2" style="align-items:center; justify-content:space-between">
                <h2 v-if="excerpt.title">{{excerpt.title}}</h2>
                <span v-if="todo.module != 'appeal' || (todo.module == 'appeal' && $route.name != 'ContactDetail')">
                    <v-btn v-if="excerpt.link && excerpt.link.name && ['demand', 'rdv'].includes(todo.module)" small rounded depressed @click="openSuperlead" color="lightgrey" class="primary--text">
                        <v-icon small color="primary" class="mr-2">$externallink_d</v-icon>
                        {{$t("linkReferenceLabel")}}
                    </v-btn>
                    <v-btn v-else-if="excerpt.link && excerpt.link.name" small rounded depressed @click="goTo(excerpt.link)" color="lightgrey" class="primary--text">
                        <v-icon small color="primary" class="mr-2">$externallink_d</v-icon>
                        {{$t("linkReferenceLabel")}}
                    </v-btn>
                    <v-btn v-else-if="todo && todo.link_hydrated && todo.link_hydrated.name && todo.module != 'appeal'" small rounded depressed @click="goToReference" color="lightgrey" class="primary--text">
                        <v-icon small color="primary" class="mr-2">$externallink_d</v-icon>
                        {{$t("linkReferenceLabel")}}
                    </v-btn>
                </span>
            </div>
            <v-chip outlined small color="primary" v-if="excerpt.status">{{$t(excerpt.status)}}</v-chip>
            <div v-if="todo.type != 'REMIND'" v-for="(content, index) in excerptContent" :key="'section_'+index">
                <h4 class="mt-3" v-if="index != 'default' && content && Object.values(content).length > 0">{{$t(index)}}</h4>
                <h4 class="mt-3" v-else-if="index == 'default'">{{$t('details')}}</h4>
                <ul  v-if="content && Object.values(content).length > 0 && index != 'criteria' && index != 'demand_linked' && index != 'items' && index != 'phones'">
                    <span v-for="(spec, index) in content" :key="'spec_'+index">
                        <li  v-if="spec && spec != '' && spec.field != 'config.comment' && spec.field != 'config.description'">
                            {{$t(spec.field)}} : 
                            <span v-if="spec.type == 'id' && (typeof(spec.value) == 'array' ||typeof(spec.value) == 'object')">
                                <b v-if="spec.value && spec.to_trad && spec.value">{{$t(spec.value)}}</b>
                                <b v-else-if="spec.value && !spec.to_trad && spec.value">{{spec.value.join(', ')}}</b>
                                <b v-else class="warning--text">{{$t('tobedefined')}}</b>
                            </span>
                            <span v-else-if="spec.type != 'datetime'">
                                <b v-if="spec.value && spec.to_trad">{{$t(spec.value)}}</b>
                                <b v-else-if="spec.value && !spec.to_trad && spec.value && spec.field != 'visio' && spec.field != 'visioLink'">{{spec.value}}</b>
                                <b v-else-if="spec.value && spec.field == 'visio'">{{spec.value && spec.value == '1' ? $t('yes') : $t('no')}}</b>
                                <b v-else-if="spec.value && spec.field == 'visioLink'"><a :href="spec.value" target="_blank">{{spec.value}}</a></b>
                                <b v-else class="warning--text">{{$t('tobedefined')}}</b>
                            </span>
                            <span v-else>
                                <b v-if="spec.value">{{parseFullDateToFr(spec.value)}}</b>
                            </span>                        
                        </li>
                        <li v-if="spec && spec != '' && (spec.field == 'config.comment' || spec.field == 'config.description') && spec.value && spec.value != ''">
                            {{$t(spec.field)}} : <b>{{spec.value}}</b>
                        </li>
                    </span>
                </ul>
                <ul v-if="content && Object.values(content).length > 0 && index == 'phones'">
                    <li v-for="(spec, index) in content" :key="'spec_'+index" v-show="spec && spec != ''">
                        <b>{{$t(spec.type)}} :</b> {{spec.number }}
                    </li>
                </ul>
                <ul v-if="content && Object.values(content).length > 0 && index == 'criteria'">
                    <li v-for="(spec, index) in content" :key="'spec_'+index" v-show="spec && spec != ''">
                        <b>{{spec.reference}} :</b> {{spec.value.text ? spec.value.text : spec.value}}
                    </li>
                </ul>
                <ul v-if="content && Object.values(content).length > 0 && index == 'demand_linked'">
                    <li v-for="(spec, index) in content" :key="'spec_'+index" v-show="spec && spec != ''">
                        {{spec.value.text ? spec.value.text : spec.value}}
                    </li>
                </ul>
                <v-expansion-panels class="no-shadow" v-if="content && Object.values(content).length > 0 && index == 'items'" accordion flat multiple>
                    <v-expansion-panel v-for="(spec, index) in content" :key="'spec_' + index" v-show="spec && spec != ''">
                        <v-expansion-panel-header>
                            {{ spec.label && spec.label.value ? spec.label.value : $t('nolabel') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ul>
                                <li><b>{{$t('reference')}} :</b> #V{{ spec.id.value }}</li>
                                <li v-for="(data, dataIndex) in spec" :key="'data_' + dataIndex" v-show="data.value && data.value != '' && !['id', 'options', 'type', 'label'].includes(dataIndex)">
                                    <b>{{ $t(dataIndex) }} : </b>
                                    <span class="text-left" v-if="data.type == 'date'">{{ parseUsDateToFr(data.value) }}</span>
                                    <span class="text-left" v-else-if="data.type == 'datetime'">{{ parseFullDateToFr(data.value) }}</span>
                                    <span class="text-left" v-else>{{ data.value }}<span v-if="data.suffix"> {{ data.suffix }}</span></span> 
                                </li>
                                <li v-for="(option, optionIndex) in spec.options.value" :key="'option_' + optionIndex" class="block">
                                    <span class="text-right"><b>{{ $t(option.reference) }} : </b></span>
                                    <span class="text-left">{{ option.value }}</span>
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div class="mt-4" v-if="todo.type == 'REMIND'">
                <b>{{ $t('start_date') }} :</b> {{ parseFullDateToFr(todo.start_date) }}<br>
                <b>{{ $t('due_date') }} :</b> {{ parseFullDateToFr(todo.due_date) }}<br>
                <b>{{ $t('config.managing_type') }} :</b> {{ todo.user_id.firstname }} {{ todo.user_id.name }}<br>
                <span v-if="todo.category_id && todo.category_id.label"><b>{{ $t('categorie') }} :</b> {{ todo.category_id.label }}<br></span>
                <span v-if="todo.comment"><b>{{ $t('remindComment') }} :</b> {{ todo.comment }}<br></span>
                <div v-if="todo.files && todo.files.length > 0" class="mt-4">
                    <b>{{$t('attachements')}} :</b>
                    <ul>
                        <li v-for="attachement in todo.files" :key="attachement.id" @click="downloadItem(attachement.url)" download outlined color="primary">
                            <span class="link" v-if="attachement.title">{{attachement.title}}</span>
                            <span class="link" v-else>{{attachement.filename.split('/').pop()}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <v-divider class="mt-6"></v-divider>
            <v-list>
                <div v-for="(button, index) in buttons"  :key="'buttons_'+index">
                    <v-list-item style="border-radius:5px;" class="listItemButtons" link @click="launchAction(index, button)" v-if="button.type != 'buttonList' && button.type != 'button' && button.type != 'alert'" :disabled="button.disabled">
                        <v-icon color="primary" class="mr-3">{{button.icon}}</v-icon>
                        {{$t(index)}}
                    </v-list-item>
                    <v-alert border='left' :icon="'$warning_s'" v-if="button.type == 'alert'" type="warning" :key="'alert'+index">
                        <b>{{$t(button.message, {var: button.username} )}}</b>
                    </v-alert>
                    <v-btn depressed color="primary" style="border-radius:5px;" class="listItemButtons" link @click="launchAction(index, button)" v-if="button.type == 'button'" :disabled="button.disabled">
                        {{$t(index)}}
                    </v-btn>
                </div>
                
                <v-menu v-if="buttonList && buttonList.content" :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                            <v-icon color="primary" class="mr-3">{{buttonList.icon}}</v-icon>
                            {{$t(buttonList.name)}}
                        </v-list-item>
                    </template>
                    <v-list>
                        <v-list-item link @click="launchAction(index, button)" v-for="(button, index) in buttonList.content" :key="'buttons_'+index">
                           {{$t(index)}}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>
        </div>
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
        <v-dialog class="rounded-lg" persistent width="700" v-model="confirmDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t(confirmTitle)}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="closeConfirm">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{$t(confirmText)}}
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog class="rounded-lg" persistent width="1200" v-model="transferCallDialog" scrollable>
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('transferCall')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="closeTransferCall">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmTransfer" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    <div v-if="$store.state.auth.user.is_presta">
                        <p style="width:100%;" class="label mb-1"><b>{{ $t('Transfert_choiceTitle') }}<span class="error--text"> *</span></b></p>
                        <v-btn-toggle dense group borderless :mandatory="true" v-model="transfert_choice" active-class="btnActive" class="mb-4">
                            <v-btn dense dark depressed class="white--text btnSpec" :style="transfert_choice == property.value ? 'background-color:var(--v-primary-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;'" 
                                v-show="!property.hide" v-for="property in transfert_choices" :key="property.value" :value="property.value"> 
                                {{$t(property.label)}}
                            </v-btn>
                        </v-btn-toggle>
                    </div>

                    <v-simple-table v-if="team.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        
                                    </th>
                                    <th class="text-left">
                                        {{$t('interlocutors')}}
                                    </th>
                                    <th class="text-left">
                                        
                                    </th>
                                    <th class="text-left">
                                        {{$t('brands')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('phones')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('leadReceiveToday')}}
                                    </th>
                                    <th class="text-left">
                                        {{$t('leadReceiveLastWeek')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="user in team"
                                :key="user.id"
                                @click="selectUser(user.id)"
                                >
                                    <td >
                                        <div style="display:flex; align-items:center;">
                                        <input type="radio" class="radioButton" :id="'radio_'+user.id" :value="user.id" v-model="userRadio" :name="'userRadio'"/>
                                        </div>
                                    </td>
                                    <td>
                                        <b>{{ user.firstname }} {{ user.name }}</b>
                                        <div v-if="user.poste && user.poste.length > 0">{{user.poste.join(', ')}}</div>
                                    </td>
                                    <td>
                                        <div v-for="(univ, index) in user.univers" class="my-1" :key="'univers_'+index">
                                            <v-chip small color="primary" outlined>{{univ}}</v-chip>
                                        </div>
                                    </td>
                                    <td>{{ user.brands.join(', ') }}</td>
                                    <td>
                                        <div v-for="(phone, index) in user.phones" class="my-1" :key="'phone_'+index">
                                            <v-chip small color="primary" outlined>{{phone.type}}</v-chip>
                                            {{phone.number}}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                       <b>{{user.leadReceiveToday}}</b>
                                    </td>
                                    <td class="text-center">
                                        <b>{{user.leadReceiveWeek}}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog class="rounded-lg" persistent width="400" v-model="qualifCallDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('qualifCall')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="cancelQualifCallDialog">{{$t('cancel')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4 text--center">
                    <p style="width:100%;" class="label mb-1"><b>{{$t('callSuceededLabel')}}<span class="error--text"> *</span></b></p>
                    <v-btn-toggle dense group borderless v-model="qualifyCall.callSucceded" active-class="btnActive" class="mb-4">
                        <v-btn dense dark depressed class="white--text btnSpec" :style="qualifyCall.callSucceded ? 'background-color:var(--v-primary-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;'" 
                            :key="'OUI'" 
                            :value="true"> 
                            {{$t('yes')}}
                        </v-btn>
                        <v-btn dense dark depressed class="white--text btnSpec" :style="qualifyCall.callSucceded === false ? 'background-color:var(--v-primary-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;'" 
                            :key="'NON'" 
                            :value="false"> 
                            {{$t('no')}}
                        </v-btn>
                    </v-btn-toggle>
                    <span v-if="qualifyCall.callSucceded">
                        <p style="width:100%;" class="label mb-1"><b>{{$t('callInterestLabel')}}<span class="error--text"> *</span></b></p>
                        
                        <!-- <v-menu :offset-y="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn depressed color="success" class="mr-2" v-bind="attrs" v-on="on">{{ $t('yesCreateDemandBtn') }}</v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="launchCallDemandForm(button)" v-for="(button, index) in demandTypes" :key="'buttons_'+index">
                                    {{$t(button)}}
                                </v-list-item>
                            </v-list>
                        </v-menu> -->
                        <v-btn depressed color="success" class="mr-2" @click="createSuperlead">{{ $t('yes') }}</v-btn>
                        <v-btn depressed color="error" @click="getForm('/appeal/cancelAppeal?appeal_id=' + todo.module_id + '&answered=true')">{{ $t('noAbandonBtn') }}</v-btn>
                        
                    </span>
                    <span v-if="qualifyCall.callSucceded === false">
                        <p style="width:100%;" class="label mb-1"><b>{{$t('trylaterLabel')}}<span class="error--text"> *</span></b></p>
                        <v-btn depressed color="success" class="mr-2" @click="getForm('/appeal/reportAppeal?appeal_id='+todo.module_id)">{{ $t('yesReportBtn') }}</v-btn>
                        <v-btn depressed color="error" @click="getForm('/appeal/cancelAppeal?appeal_id='+todo.module_id)">{{ $t('noAbandonBtn') }}</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmAbandonDialog" :max-width="400" :style="{ zIndex: 200 }" @keydown.esc="confirmAbandonDialog = false" @click:outside="confirmAbandonDialog = false">
            <v-card>
                <v-toolbar dark :color="'grey lighten-3'" dense flat>
                    <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
                        {{ $t('confirmAbandonSuperleadLabel') }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-4 black--text" v-html="$t('confirmAbandonSuperleadMessage')"></v-card-text>
                <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="confirmAbandonDialog = false">
                        {{$t('confirmationCancel')}}
                    </v-btn>
                    <v-btn color="primary" class="body-2 font-weight-bold" outlined :loading="confirmAbandonLoader" @click.native="confirmAbandon">
                        {{$t('confirmationConfirm')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";

export default {
    name:"todoObjectDrawerComponent",
    components:{FormDialogComponent},
    mixins:[dateManipulation, getForm],
    data() {
        return {
            demandTypes: [],
            buttons:[], 
            confirmDialog : false,
            confirmTitle: "",
            confirmText: "",
            actionToConfirm: {},
            form:null,
            transferCallDialog: false, 
            team:[],
            userRadio:null,
            qualifCallDialog:false, 
            qualifyCall: {
                callSucceded : null,
                interest : null, 
                trylater : null
            },
            lastActionLabel: "",
            transfert_choices: [
                { "value": "CONCESS", "label": "transfert_choice_CONCESS_label" },
                { "value": "TEAM", "label": "transfert_choice_TEAM_label" }
            ],
            transfert_choice: "CONCESS",
            confirmAbandonDialog: false,
            confirmAbandonLoader: false,
        }
    },
    methods: {
        downloadItem(url){
            window.open(url)
        },
        createSuperlead(){
            let operation_id = this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id
            let contact_id = this.todo.contact_id
            GenericDataService.getData('operation/'+operation_id+'/get').then((response) => {
                let entity_id = response.data.data.config.entity_id[0]
                let payload = {};
                payload.operation_id = operation_id;
                payload.entity_id = entity_id;
                payload.contact_id = contact_id;
                payload.appeal_id = this.todo.module_id;
                payload.module_account_id = this.todo.module_account_id;
                //GenericDataService.getData('/appeal/setAppealSuccess?appeal_id='+this.todo.module_id+'&module_account_id='+this.todo.module_account_id).then((response) =>{
                    GenericDataService.postData('/superlead/saveStep1', payload).then((response) => {
                        this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                        this.closeModal();
                    })
                //})
            })
        },
        redirectCall(id){
            this.cancelQualifCallDialog();
            if(id.hasOwnProperty('type')){
                if(this.$route.name == "ContactDetail"){
                    this.$router.replace({'query': {"demand": id.id}})
                } else {
                    this.todo.link_hydrated["query"] = {"demand": id.id}
                    this.$router.push(this.todo.link_hydrated)
                }
            } else {
                this.$router.push(this.todo.link_hydrated)
            }
            this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: false, todo: {}, excerpt: {}});
            
        },
        cancelQualifCallDialog(){
            this.qualifyCall.callSucceded = null
            this.qualifyCall.interest = null
            this.qualifyCall.trylater = null
            this.qualifCallDialog = false
        },
        openQualifyModal(){
            this.qualifCallDialog = true
        },
        selectUser(id){
           this.userRadio = id 
        },
        confirmTransfer(){
            GenericDataService.getData('/demand/transferDemand?id='+this.todo.module_id+'&user_id='+this.userRadio).then((response) => {
                this.transferCallDialog = false
                this.closeModal()
            })
        },
        closeTransferCall(){
            this.transferCallDialog = false
        },
        launchCallTransfer(){
            this.getUsersForTransfert();
            this.transferCallDialog = true
        },
        getUsersForTransfert() {
            if(this.transfert_choice === "TEAM") {
                GenericDataService.getData('user/selectListForTransfertTable').then((response) => {
                    this.team = response.data.data
                });
            } else {
                GenericDataService.getData('/operation/' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id) + '/getTeamActive').then((response) => {
                    this.team = response.data.data
                });
            }
        },
        goToReference(){
            //this.$router.push(this.todo.link_hydrated).catch(() => {});
            let routerdata = this.$router.resolve(this.todo.link_hydrated)
            window.open(routerdata.href, '_blank');
            this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: false, todo: {}, excerpt: {}});
        },
        goTo(link){
            if(link.params && Object.keys(link.params).length > 0){
                Object.keys(link.params).forEach(paramKey => {
                    link.params[paramKey] = parseInt(link.params[paramKey])
                });
            }
            let routerdata = this.$router.resolve(link)
            window.open(routerdata.href, '_blank');
            this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: false, todo: {}, excerpt: {}});
        },
        customRefresh(response){
            if(this.$route.name == 'ContactDetail'){
                this.$store.dispatch("contact/REFRESH_CONTACTDETAIL_TODO", true);  
            }
            this.cancelQualifCallDialog();
            this.closeModal()
        },
        confirmAction(){
            if(this.actionToConfirm.action == "post"){
                let url = this.actionToConfirm.postUrl.replace('{{id}}', this.todo.module_id)
                GenericDataService.postData(url).then((response) => {
                    this.confirmDialog = false
                    this.closeModal()
                })
            } 
        },
        closeConfirm(){
            this.confirmDialog = false
            this.confirmTitle = ""
            this.confirmText = ""
            this.actionToConfirm = {}
        },
        launchAction(index, action){
            if(action.confirm && action.confirm.hasOwnProperty('title')){
                this.confirmDialog = true
                this.confirmTitle = action.confirm.title
                this.confirmText = action.confirm.text
                this.actionToConfirm = action
            } else {
                if (action.action == "form"){
                    this.lastActionLabel = action.label
                    let url = action.formUrl.replace('{{id}}', this.todo.module_id)
                    this.getForm(url)
                } else if(action.action == "gotoReference"){
                    if(this.todo && this.todo.module == 'workflow'){
                        if(this.excerpt && this.excerpt.link){
                            let link = this._.cloneDeep(this.excerpt.link)
                            this.goTo(link)
                        }
                            
                    } else if(action.params){
                        let link = this._.cloneDeep(this.todo.link_hydrated)

                        action.params.forEach(element => {
                            if(!link.params[element]){
                                link.params[element] = this.todo[element].id ? this.todo[element].id : this.todo[element] 
                            }
                        });
                        //action spec depuis todo appeal
                        if(action.spec){
                            link.params['todo_id'] = this.todo.id
                            link.params['demand_id'] = this.excerptContent.demand_linked[0].refId
                        }
                        if(action.icon == "$calendar_plus_d")
                            link.params['addRdv'] = true
                        if(this.$route.name == 'ContactDetail' && this.$route.name == link.name && link.params['addRdv']){
                            this.$router.push(link).catch((e) => {
                                this.$store.dispatch("contact/SET_ADD_RDV", true);
                            })
                            
                        } else {
                            let routerdata = this.$router.resolve(link)
                            window.open(routerdata.href, '_blank');
                            //this.$router.push(link)
                        }
                        
                        this.closeModal()
                    }else {
                        let link = this._.cloneDeep(this.todo.link_hydrated)
                        let routerdata = this.$router.resolve(link)
                            window.open(routerdata.href, '_blank');
                    }
                    
                } else if(action.action == 'event'){
                    this[action.event]()
                } else if(action.action == "post"){
                    let url = action.postUrl.replace('{{id}}', this.todo.module_id)
                    GenericDataService.postData(url).then((response) => {
                        // Si c'est un Lead à traiter alors on ouvre directement le superlead
                        if(["LEAD_TO_DEAL", "RDV_TO_CONFIRM", "FEEDBACK_RDV", "FEEDBACK_PASSAGE", "FEEDBACK_INSCRIPTION"].includes(this.todo.type)) {
                            let url = "superlead/getByRdvId?id=" + this.todo.module_id;
                            if(this.todo.module === 'demand')
                                url = "superlead/getByDemandId?id=" + this.todo.module_id;

                            GenericDataService.getData(url + ('&operation_id=' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id))).then((response)=> {
                                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                            });
                        } else {
                            GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + this.todo.id).then((response) => {
                                // this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: this.todo, excerpt : response.data.data});
                                GenericDataService.getData("todo/getButtonList?todo_id="+this.todo.id).then((response) => {
                                    this.buttons = response.data.data
                                    // this.closeModal()
                                })
                            });   
                        }
                    })
                } 
            }
        },
        closeModal(){
            this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: false, todo: {}, excerpt: {}});
        },
        openCallScriptModal(){
            this.$store.dispatch("auth/SET_OPEN_CALL_SCRIPT_DRAWER", { value: true, todoId: this.todo.id });
        },
        openSuperlead() {
            let url = "";
            if(this.todo.module === 'demand')
                url = "superlead/getByDemandId?id=" + this.todo.module_id;
            else
                url = "superlead/getByRdvId?id=" + this.todo.module_id;

            GenericDataService.getData(url + ('&operation_id=' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id))).then((response)=> {
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        },
        launchAbandon() {
            this.confirmAbandonDialog = true;
        },
        confirmAbandon() {
            this.confirmAbandonLoader = true;
            GenericDataService.getData('/superlead/getByDemandId?id=' + this.todo.module_id + ('&operation_id=' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id))).then((response) => {
                GenericDataService.getData('/superlead/abandonSuperlead?id=' + response.data.data.id).then((response) => {
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                    this.closeModal();
                })
            })
        },
        launchReport() {
            GenericDataService.getData('/superlead/getByDemandId?id=' + this.todo.module_id + ('&operation_id=' + (this.todo.operation_id.id ? this.todo.operation_id.id : this.todo.operation_id))).then((response) => {
                this.getForm('/superlead/getForm?formName=report&id=' + response.data.data.id);
            })
        },
    },
    created() {
        if(this.todo){
             GenericDataService.getData("todo/getButtonList?todo_id="+this.todo.id).then((response) => {
                this.buttons = response.data.data 
            })
            GenericDataService.getData('/hard_value/getValuesArrayByType?type=demand_type').then((response) => {
                this.demandTypes = response.data.data.map((item) => item.name);
            })
        }
       
    },
    mounted() {
        if(this.todo){
             GenericDataService.getData("todo/getButtonList?todo_id="+this.todo.id).then((response) => {
                this.buttons = response.data.data
            })
            GenericDataService.getData('/hard_value/getValuesArrayByType?type=demand_type').then((response) => {
                this.demandTypes = response.data.data.map((item) => item.name);
            })
        }
        
    },
    // updated() {
    //     if(this.todo && this.todo.module && this.todo.module_id){
    //          GenericDataService.getData("todo/getButtonList?todo_id="+this.todo.id).then((response) => {
    //             this.buttons = response.data.data
    //         })
    //     }
        
    // },
    computed: {
        todo(){
            return this.$store.state.auth.todoForDrawer
        },
        excerpt(){
            return this.$store.state.auth.excerptForDrawer
        },
        buttonList(){
            for (const buttonName in this.buttons) {
                    const element = this.buttons[buttonName];
                    if(element.type == 'buttonList'){
                        element.name = buttonName
                        return element
                    }
                        
            }
            return {}
        },
        excerptContent(){
            let content = this._.cloneDeep(this.excerpt)
            delete content.title
            delete content.status
            delete content.link
            delete content.demands
            return content
        }
    },
    watch: {
        todo: {
            deep:true,
            handler(val){
                if(val.hasOwnProperty('id')){
                    GenericDataService.getData("todo/getButtonList?todo_id="+this.todo.id).then((response) => {
                        this.buttons = response.data.data
                    })
                }   
            }  
        },
        transfert_choice (transfert_choice) {
            this.getUsersForTransfert();
        },
    },
    destroyed() {
    },
    
}
</script>

<style lang="scss">
.todoDrawer {
    h4 {
        text-transform:none;
        min-height:unset;
        border-bottom:0;
    }
}
    .listItemButtons {
        &:before{
            border-radius:5px;
        }
    }
</style>
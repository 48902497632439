<template>
    <div class="userMenu">

        <!-- Bouton d'ajout global -->
        <v-menu style="z-index:111;" offset-y class="mx-1" v-if="!$store.state.auth.currentUser.profile.is_presta">
            <template v-slot:activator="{ on, attrs }">
                <v-btn  depressed v-bind="attrs" v-on="on" class="mr-6 bg-gradient pa-4" >
                    <v-icon left dark small> $plus_l </v-icon>
                    {{$t('createANew')}}
                </v-btn>
            </template>
            <v-list>
                <v-list-item dense v-for="(item, index) in baseBtns" v-if="item.menuRight ? $func.hasRight(item.menuRight) : true" :key="index" @click="selectModule(item)" exact link>
                    <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <!-- <globalAddDialogComponent v-if="openGlobalAddDialog" @closeGlobalAddDialog="openGlobalAddDialog = false" @hideGlobalAddDialog="showGlobalAddDialog = false" :showGlobalAddDialog="showGlobalAddDialog" /> -->

        <!-- Connecté en tant que -->
        <v-chip
            class="mx-1 chips"
            close
            color="warning"
            outlined
            v-if="loggedAs"
            @click:close="loggedOutAs()"
        >
            {{ $t('logged-as', loggedAs) }}
        </v-chip>

        <!--img src="img/flags/fr.svg"-->


        <!-- Sélecteur de role -->
        <v-select
            class="ml-1 mr-2 header-select"
            dense
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon=""
            :items="availableRole"
            item-text="name"
            item-value="id"
            v-model="roleId"
            v-if="this.$store.state.auth.currentUser.profile.switch_role"
            :no-data-text="$t('no-data-text')"
        >
            <template v-slot:selection="{ item }">
                <v-chip outlined small color="primary">
                    {{item.name}}
                    <v-icon small color="primary" class="ml-2">$caretdown_s</v-icon>
                </v-chip>
            </template>

            <template v-slot:item="{ item }">
                 {{ item.name }}
            </template>
        </v-select>

        <!-- Sélecteur d'account -->
        <v-select
            class="ml-1 mr-2 header-select"
            dense
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon=""
            :items="availableAccount"
            item-text="name"
            item-value="id"
            v-model="accountId"
            v-if="availableAccount.length > 1"
            :no-data-text="$t('no-data-text')"
        >

            <template v-slot:selection="{ item }">
                <v-chip outlined small color="primary">
                    {{item.name}}
                    <v-icon small color="primary" class="ml-2">$caretdown_s</v-icon>
                </v-chip>
                
                <!-- <v-btn large icon>
                    <v-img :src="`${publicPath}`+'img/flags/'+item.value+'.svg'" max-height="25px" max-width="25px"></v-img>
                </v-btn> -->
            </template>

            <template v-slot:item="{ item }">
                 {{ item.name }}
            </template>

        </v-select>

        <!-- Menu lang -->
        <!-- :items="$store.state.settings.availableLangs" -->
        <!-- <v-select
            class="ml-1 header-select"
            dense
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon=""
            v-if="$store.state.settings.availableLangs && currentUser.profile"
            :items="$store.state.settings.availableLangs"
            v-model="lang"
            :no-data-text="$t('no-data-text')"
        >

            <template v-slot:selection="{ item }">
                <v-btn large icon>
                    <v-img :src="`${publicPath}`+'img/flags/'+item.value+'.svg'" max-height="25px" max-width="25px"></v-img>
                </v-btn>
            </template>

            <template v-slot:item="{ item }">
                <v-img :src="`${publicPath}`+'img/flags/'+item.value+'.svg'" class="mr-2" max-height="20px" max-width="20px"></v-img> {{ item.text }}
            </template>

        </v-select> -->

        <!-- Menu TODO -->
        <!-- <div class="mr-1">
            <v-btn large icon @click="openCloseModal('openTodoDrawer')">
                <v-badge v-if="todos.length" class="badge-nb" color="error" :content="todos.length" :dot="false" overlap>
                    <v-icon color="primary" dark>$actions_d</v-icon>
                </v-badge>
                <v-icon color="primary" v-else dark>$actions_d</v-icon>
            </v-btn>
        </div> -->

        <!-- Menu Notifications -->
        <div class="mr-1">
            <v-btn large icon @click="openCloseModal('openNotifDrawer')">
                <v-badge v-if="notifNb > 0" class="badge-nb" color="error" :content="notifNb" :dot="false" overlap>
                    <v-icon dark color="primary">$bell_d</v-icon>
                </v-badge>
                <v-icon color="primary" v-else dark>$bell_d</v-icon>
            </v-btn>
        </div>


        <!-- Menu utilisateur -->
        <v-menu offset-y class="mx-1" v-if="currentUser.profile">
            <template v-slot:activator="{ on, attrs }">
                <v-btn large icon v-bind="attrs" v-on="on">
                    <v-avatar size="40" :key="$store.state.auth.avatarKey">
                        <v-img :src="api+'/user/'+$store.state.auth.currentUser.profile.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true&ts='+rand"></v-img>
                    </v-avatar>
                    <!-- <v-icon size="18"> $user_d </v-icon> -->
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, index) in menuUser" :key="index" @click="launchLink(item)" exact link>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>

<script>
    import {i18n, loadLanguageAsync} from '@/i18n'
    import GenericDataService from '@/services/GenericDataService'
    import refreshData from '@/mixins/mixins'
    import globalAddDialogComponent from '@/components/dialogs/globalAddDialogComponent.vue';
    import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
    import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
    import getForm from "@/mixins/mixins";

    export default {
        name:"UserMenu",
        components: { globalAddDialogComponent, FormDialogComponent, LibraryDialogComponent },
        mixins: [refreshData, getForm],
        data() {
            return {
                publicPath: process.env.BASE_URL,
                notifNb:0,
                interval:"",
                api : process.env.VUE_APP_API_URL,
                apiUrl: "",
                mutation: "",
                openGlobalAddDialog: false,
                showGlobalAddDialog: false,
                rand:0,
                form: null,
                formLib: null,
                libType: "",
                showLibraryDialog: false,
                baseBtns: [
                ],
                moduleBtn: [],
                moduleName: "",
            }
        },
        created() {
            GenericDataService.getData('notif/countNew').then((response) => {
                this.notifNb = response.data.data.nb;
                this.$store.dispatch("base/SET_NEW_COUNT_TODOS", response.data.data.countTodos);
                this.$store.dispatch("base/SET_NEW_COUNT_MESSAGE", response.data.data.countMessage);
                this.$store.dispatch("auth/SET_FRONT_VERSION_BASE", response.data.data.version);
                this.$store.dispatch("auth/SET_FRONT_VERSION_NEW", response.data.data.version);
            }).catch((e) => {})
            if(this.interval == ""){
                this.interval = setInterval(() => {
                    GenericDataService.getData('notif/countNew').then((response) => {
                        this.notifNb = response.data.data.nb;
                        this.$store.dispatch("base/SET_NEW_COUNT_TODOS", response.data.data.countTodos);
                        this.$store.dispatch("base/SET_NEW_COUNT_MESSAGE", response.data.data.countMessage);
                        this.$store.dispatch("auth/SET_FRONT_VERSION_NEW", response.data.data.version);
                    }).catch((e) => {})
                }, 30000);
            }
            GenericDataService.getData('library/getLibTypes').then((response) => {
                let libTypes = Object.values(response.data.data)
                this.baseBtns = this.baseBtns.concat(libTypes.filter((e) => !e.hideInMenu))
            });
        },
        mounted() {

            if(this.interval == ""){
                this.interval = setInterval(() => {
                    GenericDataService.getData('notif/countNew').then((response) => {
                        this.notifNb = response.data.data.nb;
                        this.$store.dispatch("base/SET_NEW_COUNT_TODOS", response.data.data.countTodos);
                        this.$store.dispatch("base/SET_NEW_COUNT_MESSAGE", response.data.data.countMessage);
                        this.$store.dispatch("auth/SET_FRONT_VERSION_NEW", response.data.data.version);
                    }).catch((e) => {})
                }, 30000);
            }
        },
        computed: {
            currentUser () {
                let user = Object.assign({}, this.$store.state.auth.currentUser)
                return user
            },
            avatar(){
                return this.$store.state.auth.currentUser.profile.avatar
            },
            getUserInitials() {
                var initials = "";
                if(this.$store.state.auth.currentUser && this.$store.state.auth.currentUser.profile) {
                    var profile = this.$store.state.auth.currentUser.profile;
                    initials += profile.firstname ? profile.firstname.charAt(0) : "";
                    initials += profile.name ? profile.name.charAt(0) : "";
                }
                return initials;
            },
            availableAccount() {
                return Object.values(this.$store.state.auth.currentUser.authorizedAccounts)
            },
            availableRole() {
                return Object.values(this.$store.state.auth.currentUser.authorizedRoles)
            },
            accountId: {
                get: function(){
                    return ""+this.$store.state.auth.currentAccountId
                },
                set: function(newValue){
                    this.setCookie('account_id', newValue, 365)
                    this.removeCookie('role_id')
                    let routeData = this.$router.resolve({ name: "Home" });
                    window.location.href = routeData.href;
                }
            },
            roleId: {
                get: function(){
                    return "" + this.$store.state.auth.currentRoleId
                },
                set: function(newValue){
                    this.setCookie('role_id', newValue, 365)
                    let routeData = this.$router.resolve({ name: "Home" });
                    window.location.href = routeData.href;
                }
            },
            lang: {
                // getter
                get: function () {
                    if(this.$store.state.auth.currentUser.parameters.lang){
                        return this.$store.state.auth.currentUser.parameters.lang
                    } else {
                        return this.$store.state.auth.currentUser.profile.lang.id
                    }
                },
                // setter
                set: function (newValue) {
                    let payload = {
                        key:'lang',
                        data: JSON.stringify(newValue)
                    }
                    this.$store.dispatch('settings/SET_PARAM', payload ).then((e) => {
                        this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true }).then((e) => {
                            loadLanguageAsync(newValue)
                        })
                    })
                }
            },
            menuUser() {
                
                let menu = [];
                for (let item of this.$store.state.auth.menuUser) {
                    if(!item.right || this.$func.hasRight(item.right))
                        menu.push(item);
                }
                if(this.packId && this.packId == 2){
                    menu = menu.filter((e) => e.title != 'set-indisponibility')
                }
                return menu;
            },
            packId(){
                if(this.$store.state.auth.currentUser.accountParameters.packId)
                    return this.$store.state.auth.currentUser.accountParameters.packId
                else 
                    return false
            },
            loggedAs () {
                return this.$store.state.auth.loggedAs;
            },
            todos: function () {
                return this.$store.state.auth.todos;
            }
        },
        methods:{
            selectModule(btn){
                this.moduleName = btn.moduleName

                if(btn.selectUrl) {
                    GenericDataService.getData(btn.selectUrl).then((response) => {
                        this.moduleBtn = response.data.data;
                    });
                } else if (btn.form) {
                    this.getForm(btn.form, false, false, null, null, true, true);
                } else if (btn.openLibraryDialog) {
                    GenericDataService.getData("/library/getFilters?libType=" + btn.openLibraryDialog).then((response) => {
                        this.formLib = response.data.data;
                        this.showLibraryDialog = true;
                        this.libType = btn.openLibraryDialog;
                    });
                }
            },
            customRefresh(response){
                console.log("customRefresh")
                if(this.moduleName == 'demand')
                    this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
                if(this.moduleName == 'operation')
                    this.$router.push({name:"OperationDetail", params: { operation_id: response.data.data.id }});
            },
            setCookie(cname, cvalue, exdays) {
                const d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                let expires = "expires="+d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            removeCookie(cname) {
                let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
                document.cookie = cname + "=; " + expires + "; path=/";
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            checkCookie() {
                let account = getCookie("account_id");
                if (account != "") {
                    this.accountId = account
                } else {
                    user = prompt("Please enter your name:", "");
                    if (user != "" && user != null) {
                    setCookie("username", user, 365);
                    }
                }
            },
            launchLink(item){
                if(item.title == 'set-indisponibility'){
                   this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", !this.$store.state.agenda.openIndispoDialog);
                } else if(item.title == 'need-help'){
                   this.$store.dispatch("auth/SET_OPEN_NEED_HELP_DIALOG", true);
                } else {
                    if(item.url){
                        this.$router.push(item.url)
                    }
                }
            },
            setIndispo() {
            },
            loggedOutAs() {
                this.$store.dispatch('settings/SET_PARAM', { key: 'loggedAs' }).then((e) => {
                    this.removeCookie('role_id');
                    let routeData = this.$router.resolve({ name: "Home" });
                    window.location.href = routeData.href;
                });
            },
            openCloseModal(modalId) {
                if(modalId === 'openTodoDrawer')
                    this.$store.dispatch("auth/SET_OPEN_TODO_DRAWER", !this.$store.state.auth.openTodoDrawer);

                if(modalId === 'openNotifDrawer'){
                    this.notifNb = 0
                    this.$store.dispatch("auth/SET_OPEN_NOTIF_DRAWER", !this.$store.state.auth.openNotifDrawer);
                }

            },
        }
    }
</script>

<style lang="scss">

    .userMenu{
        display:flex;
        align-items: center;
        justify-content: center;

        .v-btn:not(.v-btn--round).v-size--default{
            padding:0 10px;
            text-transform: capitalize;
        }

        .header-select{
            .v-select__selections input { display: none}
            .v-input__control
            {
                > .v-input__slot
                {
                    &:before,
                    &:after{
                        border:none;
                    }
                }

                .btn{
                    padding:10px 10px;
                    border-radius: 4px;

                    &:hover{
                        background-color:rgb(0,0,0,0.08);
                    }
                }

                .v-image{
                    border:1px solid #ddd;
                }
            }

        }
        .badge-nb {
            .v-badge__wrapper {
                span {
                    font-size: 10px;
                    height: 15px;
                    min-width: 15px;
                    padding: 3px 4px;
                }
            }
        }
    }




</style>

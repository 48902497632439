<template>
    <div>
        <v-list-item class="primary primarytext--text pa-5 py-3">
            <v-list-item-content class="py-0">
                <v-list-item-title class="primarytext--text d-flex align-center justify-space-between">
                <span></span>
                <v-btn depressed color="primary" class="square" @click="closeModal">
                    <v-icon small dark>$close</v-icon>
                </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <div class="pa-4">
            <div class="d-flex mt-2" style="align-items:center; justify-content:space-between">
                <h2>{{excerpt.title}}</h2>
                <v-btn v-if="!$store.state.auth.user.is_presta" small rounded depressed @click="goToReference" color="lightgrey" class="primary--text">
                    <v-icon small color="primary" class="mr-2">$externallink_d</v-icon>
                    {{$t("seeThePage")}}
                </v-btn>
            </div>
            <span>{{excerpt.comment}}</span>
            
            <div>
                <ul class="mt-3">
                    <span v-for="(spec, index) in excerptContent.details" :key="'details_' + index">
                        <li  v-if="spec && spec != ''">
                            {{$t(spec.field)}} : 
                            <span v-if="spec.type == 'id' && (typeof(spec.value) == 'array' || typeof(spec.value) == 'object')">
                                <b v-if="spec.value && spec.to_trad && spec.value">{{ $t(spec.value) }}</b>
                                <b v-else-if="spec.value && !spec.to_trad && spec.value">{{ spec.value.join(', ') }}</b>
                                <b v-else class="warning--text">{{ $t('tobedefined') }}</b>
                            </span>
                            <span v-else-if="spec.type != 'datetime'">
                                <b v-if="spec.value && spec.to_trad && spec.value">{{ $t(spec.value) }}</b>
                                <b v-else-if="spec.value && !spec.to_trad && spec.value">{{ spec.value }}</b>
                                <b v-else class="warning--text">{{ $t('tobedefined') }}</b>
                            </span>
                            <span v-else>
                                <b v-if="spec.value">{{ parseFullDateToFr(spec.value) }}</b>
                            </span>                        
                        </li>
                    </span>
                </ul>
            </div>

            <div v-if="excerptContent.attachments && excerptContent.attachments.length">
                <h2>{{ $t('attachments') }}</h2>
                <ul class="mt-3">
                    <span v-for="(attachment, index) in excerptContent.attachments" :key="'attachment_' + index">
                        <li v-for="(file, index2) in attachment.value" :key="'file_' + index2" @click="openUrl(file.url)" download>
                            <span>
                                <b class="link" v-if="file.title">{{file.title}}</b>
                                <b class="link" v-else>{{file.filename.split('/').pop()}}</b>
                            </span>                        
                        </li>
                    </span>
                </ul>
            </div>

            <div v-if="excerptContent.operationPlaces && excerptContent.operationPlaces.places && excerptContent.operationPlaces.places.value && excerptContent.operationPlaces.places.value.length">
                <h2>{{ $t('operationPlaces') }}</h2>
                <ul class="mt-3 pl-0" style="list-style-type: none;">
                    <li v-for="(place, index) in excerptContent.operationPlaces.places.value" :key="'place_' + index" @click="showGoogleMap(place)" style="cursor: pointer;">
                        <v-card color="lightgrey" class="no-shadow mb-2">
                            <v-card-title class="justify-space-between">
                                <div>
                                    <b style="font-weight: 900;">{{place.config.name}}</b>
                                    <div>{{place.config.adress}}</div> 
                                    <div>{{place.config.zip}} {{place.config.city}}</div> 
                                </div>
                                <span class="d-flex align-center">
                                    <v-icon small color="black">$map_marker_alt_s</v-icon>
                                </span>
                            </v-card-title>
                        </v-card>
                    </li>
                </ul>
            </div>

            <div>
                <h2>{{ $t('schedules') }}</h2>
                <hoursStepComponent class="mt-3 pl-0 pt-3" :loadData="true" :noButton="true" :operationId="operation.id ? operation.id : operation" :weekdays="false" :stepper="false"/>
            </div>

            <div v-if="excerptContent.entryLanding && excerptContent.entryLanding.value && excerptContent.entryLanding.value.length">
                <h2>{{ $t('landingPages') }}</h2>
                <ul class="mt-3">
                    <li v-for="(entryLanding, index) in excerptContent.entryLanding.value" :key="'entryLanding_' + index" @click="openUrl('https://' + entryLanding.config.subdomain + '.' + entryLanding.config.domain)" download>
                        <b class="link">{{ 'https://' + entryLanding.config.subdomain + '.' + entryLanding.config.domain }}</b>
                    </li>
                </ul>
            </div>

            <div v-if="excerptContent.entryCallIn && excerptContent.entryCallIn.value && excerptContent.entryCallIn.value.length">
                <h2>{{ $t('incomingCallNumbers') }}</h2>
                <ul class="mt-3">
                    <li v-for="(entryCallIn, index) in excerptContent.entryCallIn.value" :key="'entryCallIn_' + index">
                        <b>{{ entryCallIn.config.number }}</b>
                    </li>
                </ul>
            </div>

            <div v-if="excerptContent.entryEmail && excerptContent.entryEmail.value && excerptContent.entryEmail.value.length">
                <h2>{{ $t('emailForContact') }}</h2>
                <ul class="mt-3">
                    <li v-for="(entryEmail, index) in excerptContent.entryEmail.value" :key="'entryEmail_' + index">
                        <b>{{ entryEmail.config.subdomain + '@' + entryEmail.config.domain }}</b>
                    </li>
                </ul>
            </div>

            <div v-if="excerptContent.entryApi && excerptContent.entryApi.value && excerptContent.entryApi.value.length">
                <h2>{{ $t('apiToken') }}</h2>
                <ul class="mt-3">
                    <li v-for="(entryApi, index) in excerptContent.entryApi.value" :key="'entryApi_' + index">
                        <b>{{ entryApi.config.uniqId }}</b>                   
                    </li>
                </ul>
            </div>
            <v-divider class="mt-6"></v-divider>
        </div>
    </div>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import hoursStepComponent from '@/modules/operations/operationsComponents/hoursStepComponent'

export default {
    name:"OperationObjectComponent",
    components:{hoursStepComponent},
    mixins:[dateManipulation],
    data() {
        return {
            buttons:[], 
            confirmDialog : false,
            confirmTitle: "",
            confirmText: "",
            actionToConfirm: {},
            form:null,
            transferCallDialog: false, 
            team:[],
            userRadio:null
        }
    },
    methods: {
        goToReference(){
            this.$router.push(this.excerpt.link);
            this.closeModal();
        },
        closeModal(){
            this.$store.dispatch("auth/SET_OPEN_OPERATION_DRAWER", { value: false, operation: {}, excerpt: {}});
        },
        openUrl(url){
            window.open(url);
        },
        showGoogleMap(place){
            let address = place.config.name + ", " + place.config.adress + ", " + place.config.zip + ", " + place.config.city;
            window.open('https://maps.google.com/?q=' + address);
        },
    },
    created() {},
    mounted() {},
    computed: {
        operation(){
            return this.$store.state.auth.operationForDrawer
        },
        excerpt(){
            return this.$store.state.auth.excerptForDrawer
        },
        excerptContent(){
            let content = this._.cloneDeep(this.excerpt);

            if(content.entrypoints && content.entrypoints.entrypoints) {
                content.entryCallIn = {"field": "entryCallIn", "to_trad": false, "type": "array", "value": content.entrypoints.entrypoints.value.filter(entrypoint => entrypoint.type == 'entryCallIn')};
                content.entryEmail = {"field": "entryEmail", "to_trad": false, "type": "array", "value": content.entrypoints.entrypoints.value.filter(entrypoint => entrypoint.type == 'entryEmail')};
                content.entryLanding = {"field": "entryLanding", "to_trad": false, "type": "array", "value": content.entrypoints.entrypoints.value.filter(entrypoint => entrypoint.type == 'entryLanding')};
                content.entryApi = {"field": "entryApi", "to_trad": false, "type": "array", "value": content.entrypoints.entrypoints.value.filter(entrypoint => entrypoint.type == 'entryApi')};
            }

            return content
        }
    },
    watch: {},
    destroyed() {},
}
</script>

<style lang="scss">
    .listItemButtons {
        &:before{
            border-radius:5px;
        }
    }
</style>
<template>
  <div class="historyComponent">
    <v-list-item class="primary primarytext--text history-header">
      <v-list-item-content class="py-0">
        <v-list-item-title class="primarytext--text d-flex align-center justify-space-between">
          <span>{{ $t("history") }}</span>
          <v-btn depressed color="primary" class="square" @click="closeModal">
            <v-icon small dark>$close</v-icon>
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- DÉBUT DU FILTRAGE (Pour le moment il est en d-none car on a pas encore les filtres) -->
    <div class="pa-6 pb-5 d-none" :style="{ background: $vuetify.theme.themes[theme].lightgrey }">
      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
        max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field background-color="white" v-model="dateRangeText" :label="$t('dates')"
            append-icon="$calendar_alt_l" v-bind="attrs" v-on="on" outlined dense hide-details
            class="mb-3"></v-text-field>
        </template>
        <v-date-picker color="primary" v-model="dates" no-title range :first-day-of-week="1" :locale="$i18n.locale"
          :max="new Date().toISOString()">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.menu1.save(dates)">
            {{ $t('ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-expansion-panels v-model="filtersPanel">
        <v-expansion-panel :style="{ background: $vuetify.theme.themes[theme].lightgrey }">
          <v-expansion-panel-content>
            <v-select background-color="white" :items="actionTypes" :label="$t('action_types')" dense outlined
              hide-details class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            <v-select background-color="white" :items="operations" :label="$t('operation')" dense outlined hide-details
              class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            <v-select background-color="white" :items="campaigns" :label="$t('campaign')" dense outlined hide-details
              class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            <v-select background-color="white" :items="users" :label="$t('user')" dense outlined hide-details
              class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <a @click="filtersPanel = filtersPanel ? 0 : 1" class="filterButton">
        <span class="d-flex align-center">
          {{ $t('advanced_filters') }}
          <v-icon dense :class="filtersPanel === 1 ? 'mb-0 ml-2 expand-icon inactive' : 'mb-0 ml-2 expand-icon'">
            $dropdown
          </v-icon>
        </span>
      </a>
    </div>
    <!-- FIN DU FILTRAGE -->
    <div class="pa-6 pt-0">
      <v-list three-line>
        <v-alert v-if="!history.length" class="mt-3" border="left" color="info" text>
          {{ $t('emptyHistoryInfoMessage') }}
        </v-alert>
        <v-expansion-panels accordion v-else color="lightgrey">
          <v-expansion-panel v-for="(log, index) in history" :key="index">
            <v-divider v-if="index > 0"></v-divider>
            <v-expansion-panel-header class="pa-0 extralightgrey">
              <v-list-item>
                <v-list-item-avatar :color="log.icon_color" class="ml-1">
                  <v-icon dense dark>
                    {{ log.icon }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(log.title_1) }} <span v-if="log.reference" class="primary--text">{{ log.reference }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div v-if="log.user && log.user != ''" class="my-2 d-flex align-center">
                      <v-icon color="black" small class="mr-3">$user_l</v-icon>
                      <span v-if="log.realuser && log.realuser != ''">
                        <router-link v-if="log.realuser_trash === '0' && $func.hasRight('user/get') && log.realuser_id"
                          :to="{ name: 'UserDetail', params: { user_id: log.realuser_id } }">{{ log.realuser }}
                        </router-link>
                        <v-tooltip v-else-if="log.realuser_trash === '1'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ log.user }} </span>
                          </template>
                          <span>{{ $t('userDeleted') }}</span>
                        </v-tooltip>
                        <span v-else>{{ log.realuser }} </span>
                        <span v-html="$t('as_a') + '&nbsp;'"></span>
                      </span>
                      <router-link v-if="log.user_trash === '0' && $func.hasRight('user/get') && log.user_id"
                        :to="{ name: 'UserDetail', params: { user_id: log.user_id } }">{{ log.user }}</router-link>
                      <v-tooltip v-else-if="log.user_trash === '1'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{ log.user }}</span>
                        </template>
                        <span>{{ $t('userDeleted') }}</span>
                      </v-tooltip>
                      <span v-else>{{ log.user }}</span>
                    </div>
                    <div v-if="log.timestamp && log.timestamp != ''" class="d-flex align-center">
                      <v-icon color="black" small class="mr-3">$calendar_l</v-icon>
                      {{ timestampToDate(log.timestamp, 'long') }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dark dense v-for="(datas, type) in log.data" :key="type" class="mb-3 mx-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t('fields') }}</th>
                      <th v-if="type === 'new' || type === 'delete'" class="text-left">{{ $t('value') }}</th>
                      <th v-if="type === 'update'" class="text-left">{{ $t('before') }}</th>
                      <th v-if="type === 'update'" class="text-left">{{ $t('after') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(data, field) in datas">
                      <tr v-if="data" :key="field">
                        <td>{{ $t(field) }}</td>
                        <td v-if="type === 'new' && field != 'phones'">{{ data ? data : '-' }}</td>
                        <td v-if="type === 'update' && field != 'phones'">{{ data.before ? data.before : '-' }}</td>
                        <td v-if="type === 'update' && field != 'phones'">{{ data.after ? data.after : '-' }}</td>
                        <td v-if="type === 'delete'">{{ data ? data : '-' }}</td>
                        <td v-if="type === 'new' && field == 'phones'">
                          <span v-if="data">
                            <span v-for="(phone, index) in JSON.parse(data)" :key="index"
                              :class="index > 0 ? 'mt-2' : ''">
                              <v-chip outlined small style="text-transform:uppercase"
                                class="mr-2">{{ phone.type }}</v-chip>
                              {{ phone.number }}
                            </span>
                          </span>
                          <span v-else>-</span>
                        </td>
                        <td v-if="type === 'update' && field == 'phones'">
                          <span v-if="data.before && data.before != '[]'">
                            <span v-for="(phone, index) in data.before" :key="index" :class="index > 0 ? 'mt-2' : ''">
                              <v-chip outlined x-small style="text-transform:uppercase"
                                class="mr-2">{{ phone.type }}</v-chip>
                              {{ phone.number }}
                            </span>
                          </span>
                          <span v-else>-</span>
                        </td>
                        <td v-if="type === 'update' && field == 'phones'">
                          <div v-if="data.after && data.after != '[]'" class="my-1">
                            <span v-for="(phone, index) in data.after" :key="index" :class="index > 0 ? 'mt-2' : ''">
                              <v-chip outlined x-small style="text-transform:uppercase"
                                class="mr-2">{{ phone.type }}</v-chip>
                              {{ phone.number }}
                            </span>
                          </div>
                          <span v-else>-</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </div>
  </div>
</template>
<script>
import refreshData from '@/mixins/mixins'
import timestampToDate from '@/mixins/dateManipulation'

export default {
  name: "historyComponent",
  mixins: [refreshData, timestampToDate],
  data() {
    return {
      actionTypes: ["Type 1", "Type 2"],
      operations: ["Opération 1", "Opération 2"],
      campaigns: ["Campagne 1", "Campagne 2"],
      users: ["Utilisateur 1", "Utilisateur 2"],
      filtersPanel: 1,
      dates: [],
      menu1: false,
      mutation: "history/setHistory",
    }
  },
  created() {
    this.refreshData();
  },
  computed: {
    openHistoryDrawer: function () {
      return this.$store.state.history.openHistoryDrawer;
    },
    apiUrl: function () {
      return this.$store.state.history.apiUrl;
    },
    history: function () {
      return this.$store.state.history.history;
    },
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    dateRangeText() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      let dateTxt = '';

      if (this.dates.length) {
        let dateFrom = new Date(this.dates[0]);
        let dateTo = this.dates[1] ? new Date(this.dates[1]) : null;

        if (this.dates[1] && dateFrom > dateTo) {
          dateFrom = new Date(this.dates[1]);
          dateTo = new Date(this.dates[0]);
        }

        dateTxt += 'Du' + ' ' + dateFrom.toLocaleDateString(undefined, options);

        if (dateTo) {
          dateTxt += ' ' + 'au' + ' ' + dateTo.toLocaleDateString(undefined, options);
        }
      }

      return dateTxt;
    },
  },
  watch: {
    openHistoryDrawer(newCount, oldCount) {
      if (newCount)
        this.refreshData()
    }
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch("history/SET_OPEN_HISTORY_DRAWER", false);
    },
  },
};
</script>
<style lang="scss">
.historyComponent {
  .history-header {
    padding: 14px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel::before {
    display: none;
  }

  .expand-icon {
    transform: rotate(180deg);

    &.inactive {
      transform: rotate(0deg);
    }
  }

  .filterButton {
    color: var(--v-primary-base);
    text-decoration: underline;

    .v-icon {
      color: var(--v-primary-base);
    }
  }

  .v-list {

    .v-subheader,
    .v-list-item {
      padding: 0;
    }

    .v-divider--inset:not(.v-divider--vertical) {
      margin-left: 0;
      max-width: 100%;
    }
  }
}
</style>
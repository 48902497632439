<template>
    <v-card class="s-demand-add" rounded="lg" :disabled="!(superlead.contact_id && superlead.contact_id.id) || formDisabled">
        <v-card-title>
            <h5 class="pb-0"><b>{{ $t('addCustomerProject') }}</b></h5>
        </v-card-title>

        <v-card-text>
            <v-card v-for="demandType in demandTypes" :key="demandType.type" hover @click="$emit('addDemand', demandType.type)" outlined class="mb-4">
                <v-card-text class="d-flex justify-start align-center">
                    <v-img :src="demandType.url" max-height="70" max-width="70" contain></v-img>
                    <div class="ml-6">
                        <h5>{{ $t('project_' + demandType.type + '_title') }}</h5>
                        <p class="mb-0">{{ $t('project_' + demandType.type + '_text') }}</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'

export default {
    name: "SDemandAdd",
    props: ["superlead", "formDisabled"],
    components: {
    },
    data() {
        return {
            demandTypes: []
        };
    },
    methods: {
    },
    created() {
        GenericDataService.getData('/hard_value/getValuesByType?type=demand_type').then((response) => {
              let tmpTypes = response.data.data;

              for(const type in tmpTypes) {
                tmpTypes[type].type = type;
                this.demandTypes.push(tmpTypes[type]);
              }
        });
    }
}
</script>

<style lang="scss">
    .s-demand-add {
        .v-sheet.v-card--hover:hover, .v-sheet.v-card--hover:focus {
            box-shadow: none;
            border-color: var(--v-secondary-base);
        }
    }
</style>
